import { Dispatch } from 'redux';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { UnknownFunction } from '@eon-home/react-library';

import {
    WallBoxApi,
    PvBatteryApi,
    ResponseError,
    PlugAndChargeModel,
    ScheduleChargingModel,
    DynamicPriceChargingModel,
    SafetyChargingModel,
    GenericSmartChargingModel,
    SolarAssistedChargingModel,
    WallBoxDeviceResponseModel,
    WallBoxUpdateDeviceRequestModel,
    GenericSmartChargingModelModeEnum,
    WallBoxSetChargingModeResponseModel,
    WallBoxDeviceModifyModelChargingModeEnum,
    WallboxHistorySessionV2CalculatedResponseModel,
    ProgramChargingModel,
    WallBoxUpdateElectricalVehicleRequestModel,
} from '@swagger-http';

import { store } from '@src/index';
import { Scope } from '@tools/enums';
import { useWallboxConfigId } from '@store/selectors';
import { handleHistoricalEmobilityData } from '@store/actions';
import {
    LiveDataActionTypes,
    HistoricalResolution,
    EmobilityActionTypes,
} from '@store/enums';
import {
    setSuccessToast,
    getWallboxTamperNotifications,
    getEmobilityFirmwareNotification,
} from '@store/actions';
import {
    BooleanString,
    EmobilityAction,
    AggregatedDataUserState,
    ChargingModeModel,
    HistoricalEmobilityData,
} from '@store/types';
import {
    handleError,
    getResolution,
    checkForScopes,
    getRequestDates,
    getComparisonInterval,
    createRequestConfiguration,
} from '@tools/utils';

const {
    SolarAssistedCharging,
    Schedule,
    GridFriendlyCharging,
    DynamicPriceCharging,
    HomePlugAndCharge,
    SafetyCharging,
    ProgramCharging,
} = GenericSmartChargingModelModeEnum;

export const getWallboxData =
    (skipNotificationCallbacks: boolean = false) =>
    (dispatch: Dispatch<any>): Promise<EmobilityAction | void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_EV_READ])) {
            return Promise.resolve();
        }

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxStations()
            .then((data: WallBoxDeviceResponseModel[]) => {
                const configId = data[0]?.configId || undefined;

                if (configId && !skipNotificationCallbacks) {
                    dispatch(getWallboxTamperNotifications(configId));
                    dispatch(getEmobilityFirmwareNotification(configId));
                }

                return dispatch({
                    type: EmobilityActionTypes.WALLBOX_SET_DEVICE_DATA,
                    payload: {
                        wallbox: data[0],
                        isGaro:
                            data[0]?.deviceConfiguration?.manufacturer?.startsWith(
                                'GLB-',
                            ) || false,
                    },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error when getting the wallbox data:');

                dispatch({
                    type: EmobilityActionTypes.WALLBOX_SET_ERROR,
                    payload: {
                        error: e?.response,
                    },
                });
            });
    };

export const setStationData =
    (configId: string, data: WallBoxUpdateDeviceRequestModel) =>
    (dispatch: Dispatch<any>): Promise<void | boolean> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_EV_WRITE])) {
            return Promise.resolve();
        }

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxAddStation({
                configId,
                wallBoxUpdateDeviceRequestModel: data,
            })
            .then((response: WallBoxDeviceResponseModel[]) => {
                dispatch({
                    type: EmobilityActionTypes.WALLBOX_SET_DEVICE_DATA,
                    payload: {
                        wallbox: response,
                    },
                });

                dispatch(setSuccessToast());

                return true;
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error setting station data:');

                return false;
            });
    };

const setError = () => ({
    type: LiveDataActionTypes.WALLBOX_TELEMETRIES,
    payload: {
        commandError: true,
        evStateLoading: false,
    },
});

export const setGridXStationData =
    (configId: string, data: WallBoxUpdateElectricalVehicleRequestModel) =>
    (dispatch: Dispatch<any>): Promise<void | boolean> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_EV_WRITE])) {
            return Promise.resolve();
        }

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxUpdateElectricVehicleStationConfiguration({
                configId,
                wallBoxUpdateElectricalVehicleRequestModel: data,
            })
            .then(() => {
                dispatch(getWallboxData(true));

                dispatch(setSuccessToast());

                return true;
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error setting GridX station data:');

                return false;
            });
    };

export const sendCommand =
    (configId: string, command: WallBoxDeviceModifyModelChargingModeEnum) =>
    (
        dispatch: Dispatch,
    ): Promise<WallBoxSetChargingModeResponseModel | void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_EV_WRITE])) {
            return Promise.resolve();
        }

        dispatch({
            type: LiveDataActionTypes.WALLBOX_TELEMETRIES,
            payload: {
                commandError: false,
                evStateLoading: true,
                lastCommand: command,
            },
        });

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxSetChargingMode({
                configId,
                wallBoxDeviceModifyModel: {
                    // @ts-ignore
                    charging_mode: command,
                },
            })
            .then((response: any) => {
                if (response.code !== 200) {
                    dispatch(setError());
                }
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, `Error sending ${command}}:`);

                dispatch(setError());
            });
    };

export const getHistoricalWallboxData = async (
    date: Date,
    res: HistoricalResolution,
    userState: AggregatedDataUserState,
    dispatch: Dispatch,
): Promise<HistoricalEmobilityData> => {
    if (!checkForScopes([Scope.ENERGYDEVICES_EV_READ])) {
        return Promise.resolve({ prevDay: {} });
    }

    let error = false;

    const emobilityAPI = new PvBatteryApi(createRequestConfiguration());
    const resolution = getResolution(res);
    const comparisonInterval = getComparisonInterval(res);
    const { startDate, endDate } = getRequestDates(date, res, false);

    const data = await emobilityAPI
        .pvBatteryGetWallboxHistoryV2({
            fromDate: startDate,
            toDate: endDate,
            interval: resolution,
            comparisonInterval,
        })
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error getting wallbox historical data:');

            error = true;

            return [];
        });

    return handleHistoricalEmobilityData(
        date,
        res,
        error,
        dispatch,
        data,
        userState,
    );
};

export const getWallboxChargingHistory =
    (fromDate: string, toDate: string) =>
    (dispatch: Dispatch): Promise<any> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_EV_READ])) {
            return Promise.resolve();
        }

        dispatch({
            type: EmobilityActionTypes.WALLBOX_GET_CHARGING_HISTORY_LOADING,
            payload: {
                error: false,
                loading: true,
            },
        });

        return new PvBatteryApi(createRequestConfiguration())
            .pvBatteryGetWallboxHistorySessionsV2({
                fromDate,
                toDate,
            })
            .then((data: WallboxHistorySessionV2CalculatedResponseModel[]) => {
                dispatch({
                    type: EmobilityActionTypes.WALLBOX_GET_CHARGING_HISTORY,
                    payload: { data },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error getting wallbox charging history:');

                dispatch({
                    type: EmobilityActionTypes.WALLBOX_GET_CHARGING_HISTORY_ERROR,
                    payload: {
                        error: true,
                    },
                });
            })
            .finally(() => {
                dispatch({
                    type: EmobilityActionTypes.WALLBOX_GET_CHARGING_HISTORY_LOADING,
                    payload: {
                        loading: false,
                    },
                });
            });
    };

export const getWallboxChargingModesData =
    (configId: string, silent = true) =>
    (dispatch: Dispatch): Promise<void> => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_READ])) {
            return Promise.resolve();
        }

        if (!silent) {
            dispatch(setWallboxChargingModesLoading(true));
        }

        dispatch(setWallboxChargingModesError(false));

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxGetSmartChargingModes({ configId })
            .then((data: GenericSmartChargingModel[]) => {
                dispatch({
                    type: EmobilityActionTypes.WALLBOX_SET_CHARGING_MODES_DATA,
                    payload: {
                        data,
                    },
                });
            })
            .catch(async (e) => {
                if (e.response.status !== 404) {
                    await handleError(e, `Error calling GetSmartChargingMode:`);

                    dispatch(setWallboxChargingModesError(true));
                }
            })
            .finally(() => dispatch(setWallboxChargingModesLoading(false)));
    };

export const setWallboxChargingModesError =
    (error: boolean) => (dispatch: Dispatch) =>
        dispatch({
            type: EmobilityActionTypes.WALLBOX_SET_CHARGING_MODES_ERROR,
            payload: {
                error,
            },
        });

export const setWallboxChargingModesLoading =
    (loading: boolean) => (dispatch: Dispatch) =>
        dispatch({
            type: EmobilityActionTypes.WALLBOX_SET_CHARGING_MODES_LOADING,
            payload: {
                loading,
            },
        });

export const setWallboxChargingModeError =
    (mode: GenericSmartChargingModelModeEnum, error: ResponseError) =>
    (dispatch: Dispatch) => {
        let hasMatch = false;
        const data = store
            .getState()
            .emobility.wallboxData.chargingModes.data.map((item) => {
                if (!hasMatch) {
                    hasMatch = item.mode === mode;
                }

                return {
                    ...item,
                    error: hasMatch ? error : item.error,
                };
            });

        if (!hasMatch) {
            data.push({
                mode,
                error,
                parameters: { active: false },
            });
        }

        dispatch({
            type: EmobilityActionTypes.WALLBOX_SET_CHARGING_MODE_ERROR,
            payload: {
                data,
            },
        });
    };

export const toggleWallboxChargingModes =
    (mode: GenericSmartChargingModelModeEnum, isActive: boolean) =>
    (dispatch: Dispatch) => {
        const data = store
            .getState()
            .emobility.wallboxData.chargingModes.data.map((item) => ({
                ...item,
                parameters: {
                    ...item.parameters,
                    active: item.mode === mode ? isActive : false,
                },
            }));

        dispatch({
            type: EmobilityActionTypes.WALLBOX_SET_CHARGING_MODES_DATA,
            payload: {
                data,
            },
        });
    };

export const onSuccessfulWallboxChargingModeChange =
    (configId: string) => (dispatch: Dispatch) => {
        dispatch(setSuccessToast());
        dispatch(getWallboxChargingModesData(configId));
    };

export const setWallboxSmartSchedulingMode =
    (
        configId: string,
        scheduleChargingModel: ScheduleChargingModel,
        callback?: UnknownFunction,
        takeInstantEffect: BooleanString = 'true',
    ) =>
    (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_WRITE])) {
            return Promise.resolve();
        }

        dispatch(
            toggleWallboxChargingModes(Schedule, scheduleChargingModel.active),
        );

        if (typeof callback === 'function') {
            callback();
        }

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxSetSmartChargingModeToSchedule({
                configId,
                scheduleChargingModel,
                takeInstantEffect,
            })
            .then(() => {
                dispatch(onSuccessfulWallboxChargingModeChange(configId));
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    `Error calling SetSmartChargingModeToSchedule :`,
                );

                dispatch(setWallboxChargingModeError(Schedule, e));
            });
    };

// TODO EONFEH-?: rename to setWallboxSolarAssistedChargingMode and add same function for electricCar
export const setSolarAssistedChargingMode =
    (
        configId: string,
        solarAssistedChargingModel: SolarAssistedChargingModel,
        callback?: UnknownFunction,
        takeInstantEffect: BooleanString = 'true',
    ) =>
    (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_WRITE])) {
            return Promise.resolve();
        }

        dispatch(
            toggleWallboxChargingModes(
                SolarAssistedCharging,
                solarAssistedChargingModel.active,
            ),
        );

        if (typeof callback === 'function') {
            callback();
        }

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxSetSmartChargingModeToSolarAssistedCharging({
                configId,
                solarAssistedChargingModel,
                takeInstantEffect,
            })
            .then(() => {
                dispatch(onSuccessfulWallboxChargingModeChange(configId));
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    `Error calling SetSmartChargingModeToSolarAssistedCharging:`,
                );

                dispatch(setWallboxChargingModeError(SolarAssistedCharging, e));
            });
    };

export const setProgramChargingMode =
    (
        configId: string,
        programChargingModel: ProgramChargingModel,
        callback?: UnknownFunction,
        takeInstantEffect: BooleanString = 'true',
    ) =>
    async (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_WRITE])) {
            return Promise.resolve();
        }

        dispatch(
            toggleWallboxChargingModes(
                ProgramCharging,
                programChargingModel.active,
            ),
        );

        if (typeof callback === 'function') {
            callback();
        }

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxSetProgramChargingMode({
                configId,
                programChargingModel,
                takeInstantEffect,
            })
            .then(() => {
                dispatch(onSuccessfulWallboxChargingModeChange(configId));
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, `Error calling SetProgramChargingMode:`);

                dispatch(setWallboxChargingModeError(ProgramCharging, e));
            });
    };

// TODO EONFEH-?: rename to setWallboxDynamicPriceChargingMode and add same function for electricCar
export const setDynamicPriceChargingMode =
    (
        configId: string,
        dynamicPriceChargingModel: DynamicPriceChargingModel,
        callback?: UnknownFunction,
        takeInstantEffect: BooleanString = 'true',
    ) =>
    (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_WRITE])) {
            return Promise.resolve();
        }

        dispatch(
            toggleWallboxChargingModes(
                DynamicPriceCharging,
                dynamicPriceChargingModel.active,
            ),
        );

        if (typeof callback === 'function') {
            callback();
        }

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxSetSmartChargingModeToDynamicPriceCharging({
                configId,
                dynamicPriceChargingModel,
                takeInstantEffect,
            })
            .then(() => {
                dispatch(onSuccessfulWallboxChargingModeChange(configId));
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    `Error calling SetSmartChargingModeToDynamicPriceCharging:`,
                );

                dispatch(setWallboxChargingModeError(DynamicPriceCharging, e));
            });
    };

// TODO EONFEH-?: rename to setWallboxPlugAndChargeMode and add same function for electricCar
export const setPlugAndChargeMode =
    (configId: string, plugAndChargeModel: PlugAndChargeModel) =>
    (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_WRITE])) {
            return Promise.resolve();
        }

        dispatch(setWallboxChargingModesLoading(true));

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxSetSmartChargingModeToPlugAndCharge({
                configId,
                plugAndChargeModel,
            })
            .then(() => {
                dispatch(onSuccessfulWallboxChargingModeChange(configId));
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    `Error calling SetSmartChargingModeToPlugAndCharge:`,
                );

                dispatch(setWallboxChargingModeError(HomePlugAndCharge, e));
            })
            .finally(() => {
                dispatch(setWallboxChargingModesLoading(false));
            });
    };

export const setSafetyChargingMode =
    (
        configId: string,
        safetyChargingModel: SafetyChargingModel,
        callback?: UnknownFunction,
        takeInstantEffect: BooleanString = 'true',
    ) =>
    (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_WRITE])) {
            return Promise.resolve();
        }

        dispatch(
            toggleWallboxChargingModes(
                SafetyCharging,
                safetyChargingModel.active,
            ),
        );

        if (typeof callback === 'function') {
            callback();
        }

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxSetSafetyChargingMode({
                configId,
                safetyChargingModel,
                takeInstantEffect,
            })
            .then(() => {
                dispatch(onSuccessfulWallboxChargingModeChange(configId));
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, `Error calling SetSafetyChargingMode:`);

                dispatch(setWallboxChargingModeError(SafetyCharging, e));
            });
    };

export const useSetWallboxChargingMode = (
    mode: GenericSmartChargingModelModeEnum,
) => {
    const dispatch = useDispatch();
    const configId = useWallboxConfigId();

    return useCallback(
        (parameters: ChargingModeModel, onComplete?: UnknownFunction): void => {
            if (!configId) {
                return;
            }

            switch (mode) {
                case Schedule:
                case GridFriendlyCharging:
                    dispatch(
                        setWallboxSmartSchedulingMode(
                            configId,
                            parameters as ScheduleChargingModel,
                            onComplete,
                        ),
                    );
                    break;
                case SolarAssistedCharging:
                    dispatch(
                        setSolarAssistedChargingMode(
                            configId,
                            parameters as SolarAssistedChargingModel,
                            onComplete,
                        ),
                    );
                    break;
                case DynamicPriceCharging:
                    dispatch(
                        setDynamicPriceChargingMode(
                            configId,
                            parameters as DynamicPriceChargingModel,
                            onComplete,
                        ),
                    );
                    break;
                case HomePlugAndCharge:
                    dispatch(
                        setPlugAndChargeMode(
                            configId,
                            parameters as PlugAndChargeModel,
                        ),
                    );
                    break;

                case SafetyCharging:
                    dispatch(
                        setSafetyChargingMode(
                            configId,
                            parameters as SafetyChargingModel,
                            onComplete,
                        ),
                    );
                    break;
                case ProgramCharging:
                    dispatch(
                        setProgramChargingMode(
                            configId,
                            parameters as ProgramChargingModel,
                            onComplete,
                        ),
                    );
            }
        },
        [mode, configId, dispatch],
    );
};

export const getFirmwareUpdateSettings =
    (configId: string) => (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.ENERGYDEVICES_EV_READ])) {
            return Promise.resolve();
        }

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxGetWallboxFirmwareSettings({ configId })
            .then((data) => {
                dispatch({
                    type: EmobilityActionTypes.WALLBOX_SET_FIRMWARE_UPDATE_SETTINGS,
                    payload: { error: false, data },
                });
            })
            .catch(async (e: ResponseError) => {
                if (e.response.status !== 404) {
                    await handleError(
                        e,
                        `Error calling wallBoxGetWallboxFirmwareSettings:`,
                    );

                    dispatch({
                        type: EmobilityActionTypes.WALLBOX_SET_FIRMWARE_UPDATE_SETTINGS_ERROR,
                        payload: {
                            error: true,
                        },
                    });
                }
            });
    };

export const setFirmwareUpdateSettings =
    (configId: string, auto: boolean) => (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.ENERGYDEVICES_EV_WRITE])) {
            return Promise.resolve();
        }

        dispatch({
            type: EmobilityActionTypes.WALLBOX_SET_FIRMWARE_UPDATE_SETTINGS,
            payload: {
                error: false,
                data: {
                    auto,
                },
            },
        });

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxEditWallboxFirmwareSettings({
                configId,
                wallboxFirmwareUpdateSettingsModel: { auto },
            })
            .then((data) => {
                dispatch(setSuccessToast());

                dispatch({
                    type: EmobilityActionTypes.WALLBOX_SET_FIRMWARE_UPDATE_SETTINGS,
                    payload: { error: false, data },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    `Error calling wallBoxEditWallboxFirmwareSettings:`,
                );

                dispatch(getFirmwareUpdateSettings(configId));
            });
    };
