import { useState, useEffect, useMemo } from 'react';

import {
    PlugAndChargeModel,
    SafetyChargingModel,
    ScheduleChargingModel,
    DynamicPriceChargingModel,
    SolarAssistedChargingModel,
    GenericSmartChargingModelModeEnum,
    ProgramChargingModel,
} from '@swagger-http';

import { TelemetryTypes } from '@store/enums';
import { checkForScopes } from '@tools/utils';
import { ChargingModeData } from '@store/types';
import { MarketingTileModel } from '@tools/types';
import { isTheWallboxCharging } from './shared';
import { MarketingTileTypes, Scope } from '@tools/enums';
import {
    useAppSelector,
    useTelemetries,
    useIsGridXCustomer,
    useHasEmobilityTokenExpired,
    useEmobilityReadingStartDate,
} from '@store/selectors';

const {
    Schedule,
    DynamicPriceCharging,
    GridFriendlyCharging,
    SolarAssistedCharging,
    HomePlugAndCharge,
    ProgramCharging,
    SafetyCharging,
    Autopilot,
} = GenericSmartChargingModelModeEnum;

export const useHasWallbox = () =>
    !!useAppSelector((state) => state.emobility.wallboxData.wallbox?.configId);

export const useWallboxConfigId = () =>
    useAppSelector((state) => state.emobility.wallboxData.wallbox?.configId);

export const useWallboxFirmwareUpdateSettings = () =>
    useAppSelector(
        (state) => state.emobility.wallboxData.firmware.updateSettings,
    );

export const useHasWallboxTokenExpired = () => {
    const tokenExpiryDate = useAppSelector(
        (state) =>
            state.emobility.wallboxData.wallbox?.deviceGateway?.tokenExpiryDate,
    );

    return useHasEmobilityTokenExpired(tokenExpiryDate);
};

// TODO EONFEH-13779: consider putting this in emobility reducer and passing isCharging, status, isOnline as params
export const useIsWallboxCharging = () => {
    const [isCharging, setIsCharging] = useState(false);
    const {
        wallbox: { isWallboxCharging, status, isWallboxOnline },
    } = useTelemetries(TelemetryTypes.EMOBILITY);

    useEffect(() => {
        setIsCharging(
            isTheWallboxCharging({
                isWallboxCharging,
                status,
                isWallboxOnline,
            }),
        );
    }, [status, isWallboxOnline, isWallboxCharging]);

    return isCharging;
};

export const useWallboxConnectButton = (
    marketingTiles: MarketingTileModel[],
): boolean => {
    const tile = marketingTiles.find(
        (item) => item.type === MarketingTileTypes.WALLBOX,
    );

    return (
        !!tile &&
        tile.shouldRender &&
        checkForScopes([Scope.ENERGYDEVICES_EV_WRITE])
    );
};

export const useWallboxReadingStartDate = () => {
    const readingStartDate = useAppSelector(
        (state) =>
            state.emobility.wallboxData.wallbox?.deviceConfiguration
                ?.readingStartDate,
    );

    return useEmobilityReadingStartDate(readingStartDate);
};

export const useWallboxChargingModes = () => {
    const data = useAppSelector(
        (state) => state.emobility.wallboxData.chargingModes.data,
    );
    const permissions = useWallboxChargingModesPermissions();

    return data
        .filter((item) => item.mode !== GridFriendlyCharging)
        .filter((item) => permissions[item.mode]);
};

export const useWallboxSmartSchedulingData = () => {
    const schedulingData = useAppSelector((state) =>
        state.emobility.wallboxData.chargingModes.data.find(
            (item) => item.mode === Schedule,
        ),
    ) as ChargingModeData<ScheduleChargingModel> | undefined;

    const gridFriendlyData = useAppSelector((state) =>
        state.emobility.wallboxData.chargingModes.data.find(
            (item) => item.mode === GridFriendlyCharging,
        ),
    ) as ChargingModeData<ScheduleChargingModel> | undefined;

    /**
     * The smart schedule mode can use data from two
     * different sources: GridFriendly mode for UK or
     * the basic Schedule mode.
     * Currently, if we have an active GridFriendly mode,
     * then it will be used as source of data
     */
    const data = gridFriendlyData?.parameters.active
        ? gridFriendlyData
        : schedulingData;

    return useMemo(
        () => ({
            active: !!data?.parameters.active,
            hasData: !!data?.parameters,
            defaultEnd: data?.parameters.end ?? '1200',
            defaultStart: data?.parameters.start ?? '0000',
        }),
        [data?.parameters],
    );
};

// TODO EONFEH-13779: consider putting this in emobility reducer
export const useWallboxSolarAssistedChargingData = () => {
    const data = useAppSelector((state) =>
        state.emobility.wallboxData.chargingModes.data.find(
            (item) => item.mode === SolarAssistedCharging,
        ),
    ) as ChargingModeData<SolarAssistedChargingModel> | undefined;

    return useMemo(
        () => ({
            active: !!data?.parameters.active,
            pvPeakThreshold: (data?.parameters.pvPeakThreshold || 0.15) * 100,
            nightChargingEnd: data?.parameters?.nightCharging?.end || '2200',
            nightChargingStart:
                data?.parameters?.nightCharging?.start || '1700',
            nightChargingFallback: !!data?.parameters?.nightChargingFallback,
        }),
        [
            data?.parameters.active,
            data?.parameters.pvPeakThreshold,
            data?.parameters?.nightCharging?.end,
            data?.parameters?.nightCharging?.start,
            data?.parameters?.nightChargingFallback,
        ],
    );
};

export const useWallboxSafetyChargingData = () => {
    const data = useAppSelector((state) =>
        state.emobility.wallboxData.chargingModes.data.find(
            (item) => item.mode === SafetyCharging,
        ),
    ) as ChargingModeData<SafetyChargingModel> | undefined;

    const { active, minSoc, isInitialized } = useMemo(
        () => ({
            active: !!data?.parameters.active,
            minSoc: data?.parameters.minSoc ?? 20,
            isInitialized: data?.parameters.active !== undefined,
        }),
        [data?.parameters],
    );

    return useMemo(
        () => ({
            active,
            minSoc,
            isInitialized,
        }),
        [active, minSoc, isInitialized],
    );
};

export const useWallboxProgramChargingData = () => {
    const data = useAppSelector((state) =>
        state.emobility.wallboxData.chargingModes.data.find(
            (item) => item.mode === ProgramCharging,
        ),
    ) as ChargingModeData<ProgramChargingModel> | undefined;

    const DEFAULT_BATTERY_LEVEL = useMemo(() => 80, []);
    const DEFAULT_DEPARTURE_TIME = useMemo(() => '0700', []);

    const { active, departureTimestamp, minSoc, userSoc } =
        data?.parameters ?? {};

    return useMemo(
        () => ({
            active: !!active,
            departureTimestamp,
            minSoc,
            userSoc,
            DEFAULT_BATTERY_LEVEL,
            DEFAULT_DEPARTURE_TIME,
        }),
        [
            active,
            departureTimestamp,
            minSoc,
            userSoc,
            DEFAULT_BATTERY_LEVEL,
            DEFAULT_DEPARTURE_TIME,
        ],
    );
};

// TODO EONFEH-13779: consider putting this in emobility reducer
export const useWallboxPlugAndChargeData = () => {
    const data = useAppSelector((state) =>
        state.emobility.wallboxData.chargingModes.data.find(
            (item) => item.mode === HomePlugAndCharge,
        ),
    ) as ChargingModeData<PlugAndChargeModel> | undefined;

    return useMemo(
        () => ({
            active: !!data?.parameters.active,
            error: data?.error,
        }),
        [data?.error, data?.parameters.active],
    );
};

// TODO EONFEH-13779: consider putting this in emobility reducer
export const useWallboxDynamicPriceChargingData = () => {
    const data = useAppSelector((state) =>
        state.emobility.wallboxData.chargingModes.data.find(
            (item) => item.mode === DynamicPriceCharging,
        ),
    ) as ChargingModeData<DynamicPriceChargingModel> | undefined;

    return useMemo(
        () => ({
            active: !!data?.parameters.active,
            schedulingTimes: data?.parameters.schedulingTimes,
        }),
        [data?.parameters.active, data?.parameters.schedulingTimes],
    );
};

export const useWallboxLastChargingSessionData = () =>
    useAppSelector(
        (state) => state.insights?.emobility?.wallbox.lastChargingSession,
    );

export const useWallboxChargingModesPermissions = (): {
    [V in GenericSmartChargingModelModeEnum]?: boolean;
} => {
    const isGridXCustomer = useIsGridXCustomer();
    return {
        [Schedule]:
            checkForScopes([Scope.EMOBILITY_SMART_CHARGING_READ]) &&
            !isGridXCustomer,
        [SolarAssistedCharging]: checkForScopes([
            Scope.EMOBILITY_SOLAR_CHARGING_READ,
        ]),
        [ProgramCharging]:
            checkForScopes([Scope.EMOBILITY_SMART_CHARGING_READ]) &&
            isGridXCustomer,
        [GridFriendlyCharging]: checkForScopes([
            Scope.EMOBILITY_SMART_CHARGING_READ,
        ]),
        [DynamicPriceCharging]: checkForScopes([
            Scope.EMOBILITY_DYNAMIC_PRICE_CHARGING_READ,
        ]),
        [HomePlugAndCharge]: checkForScopes([
            Scope.EMOBILITY_SMART_CHARGING_READ,
        ]),
        [SafetyCharging]:
            checkForScopes([Scope.EMOBILITY_SMART_CHARGING_READ]) &&
            isGridXCustomer,
        // Autopilot mode will be for electric car only for now
        // TODO: if wallbox shall get autopilot mode, add scope below
        [Autopilot]: false,
    };
};

export const useWallboxChargingModesCount = () => {
    const permissions = useWallboxChargingModesPermissions();
    return Object.keys(permissions).reduce(
        (acc: boolean[], curr: GenericSmartChargingModelModeEnum) => {
            if (
                ![
                    GenericSmartChargingModelModeEnum.GridFriendlyCharging,
                    GenericSmartChargingModelModeEnum.HomePlugAndCharge,
                ].includes(curr) &&
                permissions[curr]
            ) {
                acc.push(true);
            }

            return acc;
        },
        [],
    ).length;
};
