/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  CustomerGatewayHealthResponseModel,
  EfficiencyCheckV2ResponseModel,
  ElectricCarHistorySessionV2CalculatedResponseModel,
  ElectricCarHistorySessionV2ResponseModel,
  ElectricCarHistoryV2ResponseModel,
  EnergyHistoryV2ResponseModel,
  Forbidden,
  ForecastEfficiencyCheckModel,
  ForecastValuesResponseModel,
  GasHistoryV2ResponseModel,
  NotFound,
  PVBatteryDeviceModel,
  PVBatteryResponseModel,
  PowerHistoryV2ResponseModel,
  PreconditionFailed,
  PvBatteryAddModel,
  PvBatteryCarbonAvoidedResponseModel,
  PvBatteryCarbonAvoidedTotalResponseModel,
  PvBatteryDonutHistoryV2ResponseModel,
  PvBatteryForecastV2ResponseModel,
  PvBatteryHistoryAggregationResponseModel,
  PvBatteryHistorySummaryResponseModel,
  PvBatteryModifyModel,
  SolarCloudHistoryV2ResponseModel,
  Unauthorized,
  WallboxHistorySessionV2CalculatedResponseModel,
  WallboxHistoryV2ResponseModel,
} from '../models';

export interface PvBatteryAddPvBatteryRequest {
    pvBatteryAddModel?: PvBatteryAddModel;
}

export interface PvBatteryGetCarbonDioxideAvoidedAggregatedRequest {
    fromDate: string;
    toDate: string;
    aggregation?: string;
    handleGaps?: string;
}

export interface PvBatteryGetCarbonDioxideAvoidedTotalRequest {
    fromDate: string;
    toDate: string;
    aggregation?: string;
    handleGaps?: string;
}

export interface PvBatteryGetEfficiencyCheckRequest {
    fromDate: string;
    toDate: string;
}

export interface PvBatteryGetEfficiencyCheck1Request {
    fromDate: string;
    toDate: string;
}

export interface PvBatteryGetElectricCarHistorySessionsV2Request {
    fromDate: string;
    toDate: string;
}

export interface PvBatteryGetElectricCarHistoryV2Request {
    fromDate: string;
    toDate: string;
    interval?: string;
    comparisonInterval?: string;
    comparisonIteration?: number;
}

export interface PvBatteryGetEnergyAggregatedV2Request {
    fromDate: string;
    toDate: string;
    interval?: string;
    comparisonInterval?: string;
    comparisonIteration?: number;
}

export interface PvBatteryGetForecastAggregatedRequest {
    fromDate: string;
    toDate: string;
    interval?: string;
    aggregation?: string;
}

export interface PvBatteryGetForecastAggregated1Request {
    fromDate: string;
    toDate: string;
    interval?: string;
}

export interface PvBatteryGetGasAggregatedV2Request {
    fromDate: string;
    toDate: string;
    interval?: string;
    comparisonInterval?: string;
    comparisonIteration?: number;
}

export interface PvBatteryGetHistoryAggregatedRequest {
    fromDate: string;
    toDate: string;
    interval?: string;
    aggregation?: string;
    handleGaps?: string;
}

export interface PvBatteryGetHistoryDonutAggregatedV2Request {
    toDate?: string;
    periods?: Array<string>;
}

export interface PvBatteryGetPowerAggregatedV2Request {
    fromDate: string;
    toDate: string;
    interval?: string;
    comparisonInterval?: string;
    comparisonIteration?: number;
}

export interface PvBatteryGetPvBatteryRequest {
    clearCache?: string;
}

export interface PvBatteryGetSolarCloudBalanceHistoryV2Request {
    fromDate: string;
    toDate: string;
    interval?: string;
}

export interface PvBatteryGetWallboxHistorySessionsV2Request {
    fromDate: string;
    toDate: string;
}

export interface PvBatteryGetWallboxHistoryV2Request {
    fromDate: string;
    toDate: string;
    interval?: string;
    comparisonInterval?: string;
    comparisonIteration?: number;
}

export interface PvBatteryUpdatePvBatteryRequest {
    configurationId: string;
    pvBatteryModifyModel: PvBatteryModifyModel;
}

/**
 * 
 */
export class PvBatteryApi extends runtime.BaseAPI {

    /**
     * Used for PV self-onboarding. Does reverse lookup for the installation data and creates installation if device is found.
     * Add PV & Battery device by serial number
     */
    async pvBatteryAddPvBatteryRaw(requestParameters: PvBatteryAddPvBatteryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PVBatteryResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:write"]);
        }

        const response = await this.request({
            path: `/api/v1/pvbattery`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.pvBatteryAddModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Used for PV self-onboarding. Does reverse lookup for the installation data and creates installation if device is found.
     * Add PV & Battery device by serial number
     */
    async pvBatteryAddPvBattery(requestParameters: PvBatteryAddPvBatteryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PVBatteryResponseModel> {
        const response = await this.pvBatteryAddPvBatteryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get last session history data with price actual and max cost calculations
     */
    async pvBatteryGeLatestWallboxHistorySessionsV2Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WallboxHistorySessionV2CalculatedResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:read"]);
        }

        const response = await this.request({
            path: `/api/v2/historical-data/wallbox/sessions/latest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get last session history data with price actual and max cost calculations
     */
    async pvBatteryGeLatestWallboxHistorySessionsV2(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WallboxHistorySessionV2CalculatedResponseModel> {
        const response = await this.pvBatteryGeLatestWallboxHistorySessionsV2Raw(initOverrides);
        return await response.value();
    }

    /**
     * Get the avoided CO2 in gramm for a give timeframe in watt aggregated by hour
     */
    async pvBatteryGetCarbonDioxideAvoidedAggregatedRaw(requestParameters: PvBatteryGetCarbonDioxideAvoidedAggregatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PvBatteryCarbonAvoidedResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetCarbonDioxideAvoidedAggregated.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetCarbonDioxideAvoidedAggregated.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.aggregation !== undefined) {
            queryParameters['aggregation'] = requestParameters.aggregation;
        }

        if (requestParameters.handleGaps !== undefined) {
            queryParameters['handleGaps'] = requestParameters.handleGaps;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/carbondioxide/avoided:read"]);
        }

        const response = await this.request({
            path: `/api/v1/pvbattery/carbondioxide/avoided/aggregated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the avoided CO2 in gramm for a give timeframe in watt aggregated by hour
     */
    async pvBatteryGetCarbonDioxideAvoidedAggregated(requestParameters: PvBatteryGetCarbonDioxideAvoidedAggregatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PvBatteryCarbonAvoidedResponseModel>> {
        const response = await this.pvBatteryGetCarbonDioxideAvoidedAggregatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the avoided CO2 in gramm for a given timeframe as a total value
     */
    async pvBatteryGetCarbonDioxideAvoidedTotalRaw(requestParameters: PvBatteryGetCarbonDioxideAvoidedTotalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PvBatteryCarbonAvoidedTotalResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetCarbonDioxideAvoidedTotal.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetCarbonDioxideAvoidedTotal.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.aggregation !== undefined) {
            queryParameters['aggregation'] = requestParameters.aggregation;
        }

        if (requestParameters.handleGaps !== undefined) {
            queryParameters['handleGaps'] = requestParameters.handleGaps;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/carbondioxide/avoided:read"]);
        }

        const response = await this.request({
            path: `/api/v1/pvbattery/carbondioxide/avoided/total`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the avoided CO2 in gramm for a given timeframe as a total value
     */
    async pvBatteryGetCarbonDioxideAvoidedTotal(requestParameters: PvBatteryGetCarbonDioxideAvoidedTotalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PvBatteryCarbonAvoidedTotalResponseModel>> {
        const response = await this.pvBatteryGetCarbonDioxideAvoidedTotalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get efficiency check data
     */
    async pvBatteryGetEfficiencyCheckRaw(requestParameters: PvBatteryGetEfficiencyCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ForecastEfficiencyCheckModel>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetEfficiencyCheck.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetEfficiencyCheck.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/forecast/pvforecast:read"]);
        }

        const response = await this.request({
            path: `/api/v1/pvbattery/efficiency`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get efficiency check data
     */
    async pvBatteryGetEfficiencyCheck(requestParameters: PvBatteryGetEfficiencyCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ForecastEfficiencyCheckModel> {
        const response = await this.pvBatteryGetEfficiencyCheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get efficiency check data
     */
    async pvBatteryGetEfficiencyCheck1Raw(requestParameters: PvBatteryGetEfficiencyCheck1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EfficiencyCheckV2ResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetEfficiencyCheck1.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetEfficiencyCheck1.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/forecast/pvforecast:read"]);
        }

        const response = await this.request({
            path: `/api/v2/pvbattery/efficiency`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get efficiency check data
     */
    async pvBatteryGetEfficiencyCheck1(requestParameters: PvBatteryGetEfficiencyCheck1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EfficiencyCheckV2ResponseModel>> {
        const response = await this.pvBatteryGetEfficiencyCheck1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get history data grouped by sessions
     */
    async pvBatteryGetElectricCarHistorySessionsV2Raw(requestParameters: PvBatteryGetElectricCarHistorySessionsV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ElectricCarHistorySessionV2ResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetElectricCarHistorySessionsV2.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetElectricCarHistorySessionsV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/historical-data/electric-car/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get history data grouped by sessions
     */
    async pvBatteryGetElectricCarHistorySessionsV2(requestParameters: PvBatteryGetElectricCarHistorySessionsV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ElectricCarHistorySessionV2ResponseModel>> {
        const response = await this.pvBatteryGetElectricCarHistorySessionsV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get history graph data v2
     */
    async pvBatteryGetElectricCarHistoryV2Raw(requestParameters: PvBatteryGetElectricCarHistoryV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ElectricCarHistoryV2ResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetElectricCarHistoryV2.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetElectricCarHistoryV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        if (requestParameters.comparisonInterval !== undefined) {
            queryParameters['comparisonInterval'] = requestParameters.comparisonInterval;
        }

        if (requestParameters.comparisonIteration !== undefined) {
            queryParameters['comparisonIteration'] = requestParameters.comparisonIteration;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/historical-data/electric-car`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get history graph data v2
     */
    async pvBatteryGetElectricCarHistoryV2(requestParameters: PvBatteryGetElectricCarHistoryV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ElectricCarHistoryV2ResponseModel>> {
        const response = await this.pvBatteryGetElectricCarHistoryV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get history graph data v2
     */
    async pvBatteryGetEnergyAggregatedV2Raw(requestParameters: PvBatteryGetEnergyAggregatedV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EnergyHistoryV2ResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetEnergyAggregatedV2.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetEnergyAggregatedV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        if (requestParameters.comparisonInterval !== undefined) {
            queryParameters['comparisonInterval'] = requestParameters.comparisonInterval;
        }

        if (requestParameters.comparisonIteration !== undefined) {
            queryParameters['comparisonIteration'] = requestParameters.comparisonIteration;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:read"]);
        }

        const response = await this.request({
            path: `/api/v2/historical-data/energy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get history graph data v2
     */
    async pvBatteryGetEnergyAggregatedV2(requestParameters: PvBatteryGetEnergyAggregatedV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EnergyHistoryV2ResponseModel>> {
        const response = await this.pvBatteryGetEnergyAggregatedV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get forecast graph data
     */
    async pvBatteryGetForecastAggregatedRaw(requestParameters: PvBatteryGetForecastAggregatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ForecastValuesResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetForecastAggregated.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetForecastAggregated.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        if (requestParameters.aggregation !== undefined) {
            queryParameters['aggregation'] = requestParameters.aggregation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/forecast/pvforecast:read"]);
        }

        const response = await this.request({
            path: `/api/v1/pvbattery/forecast`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get forecast graph data
     */
    async pvBatteryGetForecastAggregated(requestParameters: PvBatteryGetForecastAggregatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ForecastValuesResponseModel>> {
        const response = await this.pvBatteryGetForecastAggregatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get forecast graph data
     */
    async pvBatteryGetForecastAggregated1Raw(requestParameters: PvBatteryGetForecastAggregated1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PvBatteryForecastV2ResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetForecastAggregated1.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetForecastAggregated1.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/forecast/pvforecast:read"]);
        }

        const response = await this.request({
            path: `/api/v2/pvbattery/forecast`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get forecast graph data
     */
    async pvBatteryGetForecastAggregated1(requestParameters: PvBatteryGetForecastAggregated1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PvBatteryForecastV2ResponseModel>> {
        const response = await this.pvBatteryGetForecastAggregated1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get history graph data v2
     */
    async pvBatteryGetGasAggregatedV2Raw(requestParameters: PvBatteryGetGasAggregatedV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GasHistoryV2ResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetGasAggregatedV2.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetGasAggregatedV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        if (requestParameters.comparisonInterval !== undefined) {
            queryParameters['comparisonInterval'] = requestParameters.comparisonInterval;
        }

        if (requestParameters.comparisonIteration !== undefined) {
            queryParameters['comparisonIteration'] = requestParameters.comparisonIteration;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:read"]);
        }

        const response = await this.request({
            path: `/api/v2/historical-data/gas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get history graph data v2
     */
    async pvBatteryGetGasAggregatedV2(requestParameters: PvBatteryGetGasAggregatedV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GasHistoryV2ResponseModel>> {
        const response = await this.pvBatteryGetGasAggregatedV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get gateway status history for a customer
     */
    async pvBatteryGetGatewayHistoryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerGatewayHealthResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:read"]);
        }

        const response = await this.request({
            path: `/api/v1/pvbattery/gateway/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get gateway status history for a customer
     */
    async pvBatteryGetGatewayHistory(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerGatewayHealthResponseModel> {
        const response = await this.pvBatteryGetGatewayHistoryRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get gateway statuses for a customer
     */
    async pvBatteryGetGatewayStatusesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerGatewayHealthResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:read"]);
        }

        const response = await this.request({
            path: `/api/v1/pvbattery/gateway/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get gateway statuses for a customer
     */
    async pvBatteryGetGatewayStatuses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerGatewayHealthResponseModel> {
        const response = await this.pvBatteryGetGatewayStatusesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get history graph data
     */
    async pvBatteryGetHistoryAggregatedRaw(requestParameters: PvBatteryGetHistoryAggregatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PvBatteryHistoryAggregationResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetHistoryAggregated.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetHistoryAggregated.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        if (requestParameters.aggregation !== undefined) {
            queryParameters['aggregation'] = requestParameters.aggregation;
        }

        if (requestParameters.handleGaps !== undefined) {
            queryParameters['handleGaps'] = requestParameters.handleGaps;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:read"]);
        }

        const response = await this.request({
            path: `/api/v1/pvbattery/history/aggregated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get history graph data
     */
    async pvBatteryGetHistoryAggregated(requestParameters: PvBatteryGetHistoryAggregatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PvBatteryHistoryAggregationResponseModel>> {
        const response = await this.pvBatteryGetHistoryAggregatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Donut graph data v2
     */
    async pvBatteryGetHistoryDonutAggregatedV2Raw(requestParameters: PvBatteryGetHistoryDonutAggregatedV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PvBatteryDonutHistoryV2ResponseModel>>> {
        const queryParameters: any = {};

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.periods) {
            queryParameters['periods'] = requestParameters.periods;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:read"]);
        }

        const response = await this.request({
            path: `/api/v2/historical-data/donut`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get Donut graph data v2
     */
    async pvBatteryGetHistoryDonutAggregatedV2(requestParameters: PvBatteryGetHistoryDonutAggregatedV2Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PvBatteryDonutHistoryV2ResponseModel>> {
        const response = await this.pvBatteryGetHistoryDonutAggregatedV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get history summary
     */
    async pvBatteryGetHistorySummaryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PvBatteryHistorySummaryResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:read"]);
        }

        const response = await this.request({
            path: `/api/v1/pvbattery/history/summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get history summary
     */
    async pvBatteryGetHistorySummary(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PvBatteryHistorySummaryResponseModel> {
        const response = await this.pvBatteryGetHistorySummaryRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get last session history data with price actual and max cost calculations
     */
    async pvBatteryGetLatestElectricCarHistorySessionsV2Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ElectricCarHistorySessionV2CalculatedResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/historical-data/electric-car/sessions/latest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get last session history data with price actual and max cost calculations
     */
    async pvBatteryGetLatestElectricCarHistorySessionsV2(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ElectricCarHistorySessionV2CalculatedResponseModel> {
        const response = await this.pvBatteryGetLatestElectricCarHistorySessionsV2Raw(initOverrides);
        return await response.value();
    }

    /**
     * Get history graph data v2
     */
    async pvBatteryGetPowerAggregatedV2Raw(requestParameters: PvBatteryGetPowerAggregatedV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PowerHistoryV2ResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetPowerAggregatedV2.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetPowerAggregatedV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        if (requestParameters.comparisonInterval !== undefined) {
            queryParameters['comparisonInterval'] = requestParameters.comparisonInterval;
        }

        if (requestParameters.comparisonIteration !== undefined) {
            queryParameters['comparisonIteration'] = requestParameters.comparisonIteration;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:read"]);
        }

        const response = await this.request({
            path: `/api/v2/historical-data/power`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get history graph data v2
     */
    async pvBatteryGetPowerAggregatedV2(requestParameters: PvBatteryGetPowerAggregatedV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PowerHistoryV2ResponseModel>> {
        const response = await this.pvBatteryGetPowerAggregatedV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get PV & Battery devices
     */
    async pvBatteryGetPvBatteryRaw(requestParameters: PvBatteryGetPvBatteryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PVBatteryDeviceModel>>> {
        const queryParameters: any = {};

        if (requestParameters.clearCache !== undefined) {
            queryParameters['clearCache'] = requestParameters.clearCache;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:read"]);
        }

        const response = await this.request({
            path: `/api/v1/pvbattery`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get PV & Battery devices
     */
    async pvBatteryGetPvBattery(requestParameters: PvBatteryGetPvBatteryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PVBatteryDeviceModel>> {
        const response = await this.pvBatteryGetPvBatteryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get solarcloud history graph data v2
     */
    async pvBatteryGetSolarCloudBalanceHistoryV2Raw(requestParameters: PvBatteryGetSolarCloudBalanceHistoryV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SolarCloudHistoryV2ResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetSolarCloudBalanceHistoryV2.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetSolarCloudBalanceHistoryV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:read"]);
        }

        const response = await this.request({
            path: `/api/v2/historical-data/solarcloud`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get solarcloud history graph data v2
     */
    async pvBatteryGetSolarCloudBalanceHistoryV2(requestParameters: PvBatteryGetSolarCloudBalanceHistoryV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SolarCloudHistoryV2ResponseModel>> {
        const response = await this.pvBatteryGetSolarCloudBalanceHistoryV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get history data grouped by sessions
     */
    async pvBatteryGetWallboxHistorySessionsV2Raw(requestParameters: PvBatteryGetWallboxHistorySessionsV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WallboxHistorySessionV2CalculatedResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetWallboxHistorySessionsV2.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetWallboxHistorySessionsV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:read"]);
        }

        const response = await this.request({
            path: `/api/v2/historical-data/wallbox/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get history data grouped by sessions
     */
    async pvBatteryGetWallboxHistorySessionsV2(requestParameters: PvBatteryGetWallboxHistorySessionsV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WallboxHistorySessionV2CalculatedResponseModel>> {
        const response = await this.pvBatteryGetWallboxHistorySessionsV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get history graph data v2
     */
    async pvBatteryGetWallboxHistoryV2Raw(requestParameters: PvBatteryGetWallboxHistoryV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WallboxHistoryV2ResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling pvBatteryGetWallboxHistoryV2.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling pvBatteryGetWallboxHistoryV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        if (requestParameters.comparisonInterval !== undefined) {
            queryParameters['comparisonInterval'] = requestParameters.comparisonInterval;
        }

        if (requestParameters.comparisonIteration !== undefined) {
            queryParameters['comparisonIteration'] = requestParameters.comparisonIteration;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:read"]);
        }

        const response = await this.request({
            path: `/api/v2/historical-data/wallbox`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get history graph data v2
     */
    async pvBatteryGetWallboxHistoryV2(requestParameters: PvBatteryGetWallboxHistoryV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WallboxHistoryV2ResponseModel>> {
        const response = await this.pvBatteryGetWallboxHistoryV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update PV & Battery device
     */
    async pvBatteryUpdatePvBatteryRaw(requestParameters: PvBatteryUpdatePvBatteryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PVBatteryDeviceModel>> {
        if (requestParameters.configurationId === null || requestParameters.configurationId === undefined) {
            throw new runtime.RequiredError('configurationId','Required parameter requestParameters.configurationId was null or undefined when calling pvBatteryUpdatePvBattery.');
        }

        if (requestParameters.pvBatteryModifyModel === null || requestParameters.pvBatteryModifyModel === undefined) {
            throw new runtime.RequiredError('pvBatteryModifyModel','Required parameter requestParameters.pvBatteryModifyModel was null or undefined when calling pvBatteryUpdatePvBattery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:write"]);
        }

        const response = await this.request({
            path: `/api/v1/pvbattery/{configurationId}`.replace(`{${"configurationId"}}`, encodeURIComponent(String(requestParameters.configurationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.pvBatteryModifyModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Update PV & Battery device
     */
    async pvBatteryUpdatePvBattery(requestParameters: PvBatteryUpdatePvBatteryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PVBatteryDeviceModel> {
        const response = await this.pvBatteryUpdatePvBatteryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
