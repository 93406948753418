/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  ConsentVersionResponseModel,
  Forbidden,
  IsGridXUserResponseModel,
  NotFound,
  SaleEditModel,
  SaleModel,
  TenantAffiliateResponseModel,
  Unauthorized,
  UserAccountChangeAddressModel,
  UserDataConsentsModel,
  UserDataDownloadInfoModel,
  UserDataDownloadUriModel,
  UserEncryptedReferenceModel,
  UserModel,
  UserPreferenceAddModel,
  UserPreferenceModel,
} from '../models';

export interface UserChangeAddressRequest {
    userAccountChangeAddressModel?: UserAccountChangeAddressModel;
}

export interface UserDeletePreferenceRequest {
    key: string;
}

export interface UserEncryptedReferenceRequest {
    urlEncode?: string;
}

export interface UserGetDownloadUriRequest {
    fileFormat: string;
}

export interface UserGetSalesRequest {
    completed?: string;
}

export interface UserPatchSaleRequest {
    saleId: string;
    saleEditModel?: SaleEditModel;
}

export interface UserPreferencesRequest {
    key?: string;
}

export interface UserPutConsentRequest {
    userDataConsentsModel: UserDataConsentsModel;
}

export interface UserReactivateAccountRequest {
    requestId: string;
    redirect?: string;
}

export interface UserUpdatePreferenceRequest {
    key: string;
    userPreferenceAddModel: UserPreferenceAddModel;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Change user address ZIP code and country(optional)
     */
    async userChangeAddressRaw(requestParameters: UserChangeAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:write"]);
        }

        const response = await this.request({
            path: `/api/v1/me/address`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userAccountChangeAddressModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change user address ZIP code and country(optional)
     */
    async userChangeAddress(requestParameters: UserChangeAddressRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userChangeAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Remove user preference
     */
    async userDeletePreferenceRaw(requestParameters: UserDeletePreferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling userDeletePreference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:write"]);
        }

        const response = await this.request({
            path: `/api/v1/me/preferences/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove user preference
     */
    async userDeletePreference(requestParameters: UserDeletePreferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userDeletePreferenceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userDeleteUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:write"]);
        }

        const response = await this.request({
            path: `/api/v1/me`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userDeleteUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userDeleteUserRaw(initOverrides);
    }

    /**
     * Get an encrypted reference for this user
     */
    async userEncryptedReferenceRaw(requestParameters: UserEncryptedReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEncryptedReferenceModel>> {
        const queryParameters: any = {};

        if (requestParameters.urlEncode !== undefined) {
            queryParameters['urlEncode'] = requestParameters.urlEncode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/me/encrypted-reference`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get an encrypted reference for this user
     */
    async userEncryptedReference(requestParameters: UserEncryptedReferenceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEncryptedReferenceModel> {
        const response = await this.userEncryptedReferenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an information, that the CMS content has been changed since user has given last consent. Retriggers the consent dialog.
     */
    async userGetCmsDataOfConsentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsentVersionResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/me/gdpr/consent/cms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get an information, that the CMS content has been changed since user has given last consent. Retriggers the consent dialog.
     */
    async userGetCmsDataOfConsent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsentVersionResponseModel> {
        const response = await this.userGetCmsDataOfConsentRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get users consent information regarding T&C and privacy policy
     */
    async userGetConsentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDataConsentsModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/me/gdpr/consent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get users consent information regarding T&C and privacy policy
     */
    async userGetConsent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDataConsentsModel> {
        const response = await this.userGetConsentRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get GDPR Export
     */
    async userGetDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDataDownloadInfoModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/me/gdpr/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get GDPR Export
     */
    async userGetData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDataDownloadInfoModel> {
        const response = await this.userGetDataRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get GDPR download url
     */
    async userGetDownloadUriRaw(requestParameters: UserGetDownloadUriRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDataDownloadUriModel>> {
        if (requestParameters.fileFormat === null || requestParameters.fileFormat === undefined) {
            throw new runtime.RequiredError('fileFormat','Required parameter requestParameters.fileFormat was null or undefined when calling userGetDownloadUri.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/me/gdpr/download_uri/{fileFormat}`.replace(`{${"fileFormat"}}`, encodeURIComponent(String(requestParameters.fileFormat))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get GDPR download url
     */
    async userGetDownloadUri(requestParameters: UserGetDownloadUriRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDataDownloadUriModel> {
        const response = await this.userGetDownloadUriRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of sales
     */
    async userGetSalesRaw(requestParameters: UserGetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SaleModel>>> {
        const queryParameters: any = {};

        if (requestParameters.completed !== undefined) {
            queryParameters['completed'] = requestParameters.completed;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/me/sales`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get list of sales
     */
    async userGetSales(requestParameters: UserGetSalesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SaleModel>> {
        const response = await this.userGetSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get user tenant affiliate info
     */
    async userGetTenantAffiliateAssignmentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TenantAffiliateResponseModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/me/tenant-affiliate-assignment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get user tenant affiliate info
     */
    async userGetTenantAffiliateAssignment(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TenantAffiliateResponseModel>> {
        const response = await this.userGetTenantAffiliateAssignmentRaw(initOverrides);
        return await response.value();
    }

    /**
     * Check if is GridX User
     */
    async userIsGridXRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IsGridXUserResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/me/isGridX`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Check if is GridX User
     */
    async userIsGridX(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IsGridXUserResponseModel> {
        const response = await this.userIsGridXRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get session user
     */
    async userMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get session user
     */
    async userMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserModel> {
        const response = await this.userMeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates steps for an existing sale
     */
    async userPatchSaleRaw(requestParameters: UserPatchSaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SaleModel>> {
        if (requestParameters.saleId === null || requestParameters.saleId === undefined) {
            throw new runtime.RequiredError('saleId','Required parameter requestParameters.saleId was null or undefined when calling userPatchSale.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:write"]);
        }

        const response = await this.request({
            path: `/api/v1/me/sales/{saleId}`.replace(`{${"saleId"}}`, encodeURIComponent(String(requestParameters.saleId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.saleEditModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates steps for an existing sale
     */
    async userPatchSale(requestParameters: UserPatchSaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SaleModel> {
        const response = await this.userPatchSaleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get user preferences
     */
    async userPreferencesRaw(requestParameters: UserPreferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserPreferenceModel>>> {
        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/me/preferences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get user preferences
     */
    async userPreferences(requestParameters: UserPreferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserPreferenceModel>> {
        const response = await this.userPreferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set users consent information regarding T&C and privacy policy
     */
    async userPutConsentRaw(requestParameters: UserPutConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsentVersionResponseModel>> {
        if (requestParameters.userDataConsentsModel === null || requestParameters.userDataConsentsModel === undefined) {
            throw new runtime.RequiredError('userDataConsentsModel','Required parameter requestParameters.userDataConsentsModel was null or undefined when calling userPutConsent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:write"]);
        }

        const response = await this.request({
            path: `/api/v1/me/gdpr/consent`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userDataConsentsModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Set users consent information regarding T&C and privacy policy
     */
    async userPutConsent(requestParameters: UserPutConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsentVersionResponseModel> {
        const response = await this.userPutConsentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reactivate user account
     */
    async userReactivateAccountRaw(requestParameters: UserReactivateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling userReactivateAccount.');
        }

        const queryParameters: any = {};

        if (requestParameters.redirect !== undefined) {
            queryParameters['redirect'] = requestParameters.redirect;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/me/reactivate/{requestId}`.replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reactivate user account
     */
    async userReactivateAccount(requestParameters: UserReactivateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userReactivateAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Add GDPR request
     */
    async userRequestDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:write"]);
        }

        const response = await this.request({
            path: `/api/v1/me/gdpr/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add GDPR request
     */
    async userRequestData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userRequestDataRaw(initOverrides);
    }

    /**
     * Update user preference
     */
    async userUpdatePreferenceRaw(requestParameters: UserUpdatePreferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling userUpdatePreference.');
        }

        if (requestParameters.userPreferenceAddModel === null || requestParameters.userPreferenceAddModel === undefined) {
            throw new runtime.RequiredError('userPreferenceAddModel','Required parameter requestParameters.userPreferenceAddModel was null or undefined when calling userUpdatePreference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:write"]);
        }

        const response = await this.request({
            path: `/api/v1/me/preferences/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userPreferenceAddModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update user preference
     */
    async userUpdatePreference(requestParameters: UserUpdatePreferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userUpdatePreferenceRaw(requestParameters, initOverrides);
    }

    /**
     * Make the last GDPR request available again
     */
    async userValidateRequestDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:write"]);
        }

        const response = await this.request({
            path: `/api/v1/me/gdpr/export/validateLastRequest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Make the last GDPR request available again
     */
    async userValidateRequestData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userValidateRequestDataRaw(initOverrides);
    }

}
