/* tslint:disable */
/* eslint-disable */
/**
 * 
 * @export
 * @interface AccountChangeEmailModel
 */
export interface AccountChangeEmailModel {
    /**
     * 
     * @type {string}
     * @memberof AccountChangeEmailModel
     */
    email: string;
}
/**
 * 
 * @export
 * @interface AdvancedAuthenticationRequestModel
 */
export interface AdvancedAuthenticationRequestModel {
    /**
     * Optional for native apps that don't support cookies
     * @type {string}
     * @memberof AdvancedAuthenticationRequestModel
     */
    refreshToken?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvancedAuthenticationRequestModel
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvancedAuthenticationRequestModel
     */
    grantType: string;
}
/**
 * 
 * @export
 * @interface AirConditioningCapabilitySet
 */
export interface AirConditioningCapabilitySet {
    /**
     * 
     * @type {Celsius}
     * @memberof AirConditioningCapabilitySet
     */
    cool?: Celsius;
    /**
     * 
     * @type {Celsius}
     * @memberof AirConditioningCapabilitySet
     */
    heat?: Celsius;
}
/**
 * 
 * @export
 * @interface AirConditioningDeviceCapabilities
 */
export interface AirConditioningDeviceCapabilities {
    /**
     * 
     * @type {Array<string>}
     * @memberof AirConditioningDeviceCapabilities
     */
    read?: Array<AirConditioningDeviceCapabilitiesReadEnum>;
    /**
     * 
     * @type {AirConditioningDeviceWriteCapabilities}
     * @memberof AirConditioningDeviceCapabilities
     */
    write?: AirConditioningDeviceWriteCapabilities;
}

/**
* @export
* @enum {string}
*/
export enum AirConditioningDeviceCapabilitiesReadEnum {
    InsideTemperature = 'insideTemperature',
    Humidity = 'humidity',
    FanSpeeds = 'fanSpeeds',
    Swings = 'swings',
    Mode = 'mode'
}

/**
 * 
 * @export
 * @interface AirConditioningDeviceWriteCapabilities
 */
export interface AirConditioningDeviceWriteCapabilities {
    /**
     * 
     * @type {string}
     * @memberof AirConditioningDeviceWriteCapabilities
     */
    power?: AirConditioningDeviceWriteCapabilitiesPowerEnum;
    /**
     * 
     * @type {AirConditioningCapabilitySet}
     * @memberof AirConditioningDeviceWriteCapabilities
     */
    temperatures?: AirConditioningCapabilitySet;
    /**
     * 
     * @type {string}
     * @memberof AirConditioningDeviceWriteCapabilities
     */
    fanSpeeds?: AirConditioningDeviceWriteCapabilitiesFanSpeedsEnum;
    /**
     * 
     * @type {string}
     * @memberof AirConditioningDeviceWriteCapabilities
     */
    mode?: AirConditioningDeviceWriteCapabilitiesModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AirConditioningDeviceWriteCapabilitiesPowerEnum {
    On = 'ON',
    Off = 'OFF'
}
/**
* @export
* @enum {string}
*/
export enum AirConditioningDeviceWriteCapabilitiesFanSpeedsEnum {
    Low = 'LOW',
    Middle = 'MIDDLE',
    High = 'HIGH',
    Auto = 'AUTO'
}
/**
* @export
* @enum {string}
*/
export enum AirConditioningDeviceWriteCapabilitiesModeEnum {
    Heat = 'HEAT',
    Cool = 'COOL'
}

/**
 * 
 * @export
 * @interface AuthenticationRefreshRequestModel
 */
export interface AuthenticationRefreshRequestModel {
    /**
     * Optional for native apps that don't support cookies
     * @type {string}
     * @memberof AuthenticationRefreshRequestModel
     */
    refreshToken?: string;
}
/**
 * 
 * @export
 * @interface AuthenticationResponseModel
 */
export interface AuthenticationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseModel
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseModel
     */
    refreshToken?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationResponseModel
     */
    expiresIn?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseModel
     */
    scope?: string;
}
/**
 * 
 * @export
 * @interface AutopilotChargingModel
 */
export interface AutopilotChargingModel {
    /**
     * 
     * @type {boolean}
     * @memberof AutopilotChargingModel
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof AutopilotChargingModel
     */
    targetSoc: number;
    /**
     * the departureTime in the format: HHMM
     * @type {string}
     * @memberof AutopilotChargingModel
     */
    departureTime: string;
    /**
     * 
     * @type {number}
     * @memberof AutopilotChargingModel
     */
    minimumSoc?: number;
    /**
     * The timezone which this site is scheduled in. Will be set to the sites timezone if not provided
     * @type {string}
     * @memberof AutopilotChargingModel
     */
    timezone?: string;
}
/**
 * 
 * @export
 * @interface BadRequest
 */
export interface BadRequest {
    /**
     * The error name
     * @type {string}
     * @memberof BadRequest
     */
    name: string;
    /**
     * An error message
     * @type {string}
     * @memberof BadRequest
     */
    message: string;
    /**
     * The status code of the exception
     * @type {number}
     * @memberof BadRequest
     */
    status: number;
    /**
     * A list of related errors
     * @type {Array<GenericError>}
     * @memberof BadRequest
     */
    errors?: Array<GenericError>;
    /**
     * The stack trace (only in development mode)
     * @type {string}
     * @memberof BadRequest
     */
    stack?: string;
}
/**
 * 
 * @export
 * @interface BatteryModel
 */
export interface BatteryModel {
    /**
     * 
     * @type {boolean}
     * @memberof BatteryModel
     */
    installed?: boolean;
    /**
     * Value in Wh
     * @type {number}
     * @memberof BatteryModel
     */
    capacity?: number;
    /**
     * 
     * @type {string}
     * @memberof BatteryModel
     */
    manufacturer: string;
    /**
     * 
     * @type {string}
     * @memberof BatteryModel
     */
    model: string;
    /**
     * Value in W
     * @type {number}
     * @memberof BatteryModel
     */
    maxPower?: number;
}
/**
 * 
 * @export
 * @interface BenchmarkCategoryResponseModel
 */
export interface BenchmarkCategoryResponseModel {
    /**
     * Energy fuel source
     * @type {string}
     * @memberof BenchmarkCategoryResponseModel
     */
    type?: BenchmarkCategoryResponseModelTypeEnum;
    /**
     * Disaggregation category name
     * @type {string}
     * @memberof BenchmarkCategoryResponseModel
     */
    category?: BenchmarkCategoryResponseModelCategoryEnum;
    /**
     * Cost currency
     * @type {string}
     * @memberof BenchmarkCategoryResponseModel
     */
    currency?: string;
    /**
     * User home consumption in kWh
     * @type {number}
     * @memberof BenchmarkCategoryResponseModel
     */
    energy?: number;
    /**
     * User consumption cost
     * @type {number}
     * @memberof BenchmarkCategoryResponseModel
     */
    cost?: number;
    /**
     * Similar homes average consumption in kWh
     * @type {number}
     * @memberof BenchmarkCategoryResponseModel
     */
    similarHomesEnergy?: number;
    /**
     * Similar homes average consumption cost
     * @type {number}
     * @memberof BenchmarkCategoryResponseModel
     */
    similarHomesCost?: number;
    /**
     * Insights based on value comparison
     * @type {Array<BenchmarkInsight>}
     * @memberof BenchmarkCategoryResponseModel
     */
    insights?: Array<BenchmarkInsight>;
}

/**
* @export
* @enum {string}
*/
export enum BenchmarkCategoryResponseModelTypeEnum {
    Total = 'total',
    Electricity = 'electricity',
    Gas = 'gas'
}
/**
* @export
* @enum {string}
*/
export enum BenchmarkCategoryResponseModelCategoryEnum {
    Standby = 'standby',
    FridgeFreezer = 'fridge_freezer',
    Appliances = 'appliances',
    Cooking = 'cooking',
    Entertainment = 'entertainment',
    Heating = 'heating',
    Light = 'light',
    Others = 'others',
    Active = 'active',
    WaterHeating = 'water_heating'
}

/**
 * 
 * @export
 * @interface BenchmarkInsight
 */
export interface BenchmarkInsight {
    /**
     * Insight key, to be used for i18n client side
     * @type {string}
     * @memberof BenchmarkInsight
     */
    type?: string;
    /**
     * Key/value pairs to be interpolated into i18n template
     * @type {object}
     * @memberof BenchmarkInsight
     */
    variables?: object;
}
/**
 * 
 * @export
 * @interface BenchmarkResponseModel
 */
export interface BenchmarkResponseModel {
    /**
     * Cost currency
     * @type {string}
     * @memberof BenchmarkResponseModel
     */
    currency?: string;
    /**
     * Benchmark by category
     * @type {Array<BenchmarkCategoryResponseModel>}
     * @memberof BenchmarkResponseModel
     */
    categories?: Array<BenchmarkCategoryResponseModel>;
}
/**
 * 
 * @export
 * @interface BenefitsResponseModel
 */
export interface BenefitsResponseModel {
    /**
     * 
     * @type {SavingsResponseModel}
     * @memberof BenefitsResponseModel
     */
    savings: SavingsResponseModel;
    /**
     * 
     * @type {Co2ResponseModel}
     * @memberof BenefitsResponseModel
     */
    co2: Co2ResponseModel;
}
/**
 * 
 * @export
 * @interface CampaignResponseModel
 */
export interface CampaignResponseModel {
    /**
     * 
     * @type {string}
     * @memberof CampaignResponseModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignResponseModel
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignResponseModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignResponseModel
     */
    start: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignResponseModel
     */
    stop: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignResponseModel
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CampaignResponseModel
     */
    tenant?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignResponseModel
     */
    featureGroup?: string;
}
/**
 * 
 * @export
 * @interface CarbonAvoidedSectionResponseModel
 */
export interface CarbonAvoidedSectionResponseModel {
    /**
     * CO2 in gramm for given timeframe
     * @type {number}
     * @memberof CarbonAvoidedSectionResponseModel
     */
    avoidedCO2: number;
}
/**
 * 
 * @export
 * @interface Celsius
 */
export interface Celsius {
    /**
     * 
     * @type {NumberRange}
     * @memberof Celsius
     */
    celsius?: NumberRange;
}
/**
 * 
 * @export
 * @interface Co2ResponseModel
 */
export interface Co2ResponseModel {
    /**
     * 
     * @type {ValueUnitResponseModel}
     * @memberof Co2ResponseModel
     */
    savings?: ValueUnitResponseModel;
    /**
     * 
     * @type {ValueUnitResponseModel}
     * @memberof Co2ResponseModel
     */
    co2SavingDurationByYou?: ValueUnitResponseModel;
    /**
     * 
     * @type {ValueUnitResponseModel}
     * @memberof Co2ResponseModel
     */
    co2LifeTime: ValueUnitResponseModel;
}
/**
 * 
 * @export
 * @interface CommandResponseModel
 */
export interface CommandResponseModel {
    /**
     * Success message or error message in case of command error
     * @type {string}
     * @memberof CommandResponseModel
     */
    message?: string;
    /**
     * 200 or the command error code
     * @type {number}
     * @memberof CommandResponseModel
     */
    code?: number;
}
/**
 * 
 * @export
 * @interface Conflict
 */
export interface Conflict {
    /**
     * The error name
     * @type {string}
     * @memberof Conflict
     */
    name: string;
    /**
     * An error message
     * @type {string}
     * @memberof Conflict
     */
    message: string;
    /**
     * The status code of the exception
     * @type {number}
     * @memberof Conflict
     */
    status: number;
    /**
     * A list of related errors
     * @type {Array<GenericError>}
     * @memberof Conflict
     */
    errors?: Array<GenericError>;
    /**
     * The stack trace (only in development mode)
     * @type {string}
     * @memberof Conflict
     */
    stack?: string;
}
/**
 * 
 * @export
 * @interface ConsentVersionResponseModel
 */
export interface ConsentVersionResponseModel {
    /**
     * 
     * @type {number}
     * @memberof ConsentVersionResponseModel
     */
    currentCmsVersion?: number;
    /**
     * 
     * @type {number}
     * @memberof ConsentVersionResponseModel
     */
    consentedVersion?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConsentVersionResponseModel
     */
    hasNewVersion?: boolean;
}
/**
 * 
 * @export
 * @interface ControlChargingFailureReason
 */
export interface ControlChargingFailureReason {
    /**
     * 
     * @type {string}
     * @memberof ControlChargingFailureReason
     */
    type: ControlChargingFailureReasonTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ControlChargingFailureReason
     */
    detail: string;
}

/**
* @export
* @enum {string}
*/
export enum ControlChargingFailureReasonTypeEnum {
    NoResponse = 'NO_RESPONSE',
    FailedPrecondition = 'FAILED_PRECONDITION',
    Unnecessary = 'UNNECESSARY',
    Conflict = 'CONFLICT',
    RequestedCancellation = 'REQUESTED_CANCELLATION',
    NotFound = 'NOT_FOUND'
}

/**
 * 
 * @export
 * @interface CustomerGatewayHealthResponseModel
 */
export interface CustomerGatewayHealthResponseModel {
    /**
     * 
     * @type {number}
     * @memberof CustomerGatewayHealthResponseModel
     */
    count: number;
    /**
     * 
     * @type {Array<CustomerGatewayResponseModel>}
     * @memberof CustomerGatewayHealthResponseModel
     */
    list: Array<CustomerGatewayResponseModel>;
}
/**
 * 
 * @export
 * @interface CustomerGatewayResponseModel
 */
export interface CustomerGatewayResponseModel {
    /**
     * 
     * @type {string}
     * @memberof CustomerGatewayResponseModel
     */
    siteId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerGatewayResponseModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerGatewayResponseModel
     */
    deviceType?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerGatewayResponseModel
     */
    level?: CustomerGatewayResponseModelLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerGatewayResponseModel
     */
    createdOn?: string;
}

/**
* @export
* @enum {string}
*/
export enum CustomerGatewayResponseModelLevelEnum {
    Ok = 'ok',
    Alerting = 'alerting',
    NoData = 'no_data'
}

/**
 * 
 * @export
 * @interface DeviceModel
 */
export interface DeviceModel {
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    roomId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    deviceType: string;
    /**
     * 
     * @type {object}
     * @memberof DeviceModel
     */
    deviceConfiguration: object;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceModel
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceModel
     */
    controllable: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeviceModel
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    icon?: string;
}
/**
 * 
 * @export
 * @interface DeviceModifyModel
 */
export interface DeviceModifyModel {
    /**
     * 
     * @type {string}
     * @memberof DeviceModifyModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceModifyModel
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceModifyModel
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeviceModifyModel
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceModifyModel
     */
    icon?: string;
}
/**
 * 
 * @export
 * @interface DisaggregationCategoryTotal
 */
export interface DisaggregationCategoryTotal {
    /**
     * Total consumption in kWh
     * @type {number}
     * @memberof DisaggregationCategoryTotal
     */
    energy?: number;
    /**
     * Total cost
     * @type {number}
     * @memberof DisaggregationCategoryTotal
     */
    cost?: number;
    /**
     * Disaggregation category name
     * @type {string}
     * @memberof DisaggregationCategoryTotal
     */
    name?: DisaggregationCategoryTotalNameEnum;
}

/**
* @export
* @enum {string}
*/
export enum DisaggregationCategoryTotalNameEnum {
    Standby = 'standby',
    FridgeFreezer = 'fridge_freezer',
    Appliances = 'appliances',
    Cooking = 'cooking',
    Entertainment = 'entertainment',
    Heating = 'heating',
    Light = 'light',
    Others = 'others',
    Active = 'active',
    WaterHeating = 'water_heating'
}

/**
 * 
 * @export
 * @interface DisaggregationInsight
 */
export interface DisaggregationInsight {
    /**
     * Insight key, to be used for i18n client side
     * @type {string}
     * @memberof DisaggregationInsight
     */
    type?: DisaggregationInsightTypeEnum;
    /**
     * Category
     * @type {string}
     * @memberof DisaggregationInsight
     */
    category?: DisaggregationInsightCategoryEnum;
    /**
     * Key/value pairs to be interpolated into i18n template
     * @type {object}
     * @memberof DisaggregationInsight
     */
    variables?: object;
}

/**
* @export
* @enum {string}
*/
export enum DisaggregationInsightTypeEnum {
    Increase = 'increase',
    Decrease = 'decrease'
}
/**
* @export
* @enum {string}
*/
export enum DisaggregationInsightCategoryEnum {
    Standby = 'standby',
    FridgeFreezer = 'fridge_freezer',
    Appliances = 'appliances',
    Cooking = 'cooking',
    Entertainment = 'entertainment',
    Heating = 'heating',
    Light = 'light',
    Others = 'others',
    Active = 'active',
    WaterHeating = 'water_heating'
}

/**
 * 
 * @export
 * @interface DisaggregationResponseModel
 */
export interface DisaggregationResponseModel {
    /**
     * Energy fuel source
     * @type {string}
     * @memberof DisaggregationResponseModel
     */
    type?: DisaggregationResponseModelTypeEnum;
    /**
     * Start date of the aggregated period
     * @type {string}
     * @memberof DisaggregationResponseModel
     */
    date?: string;
    /**
     * Cost currency
     * @type {string}
     * @memberof DisaggregationResponseModel
     */
    currency?: string;
    /**
     * 
     * @type {DisaggregationTotal}
     * @memberof DisaggregationResponseModel
     */
    total?: DisaggregationTotal;
    /**
     * Totals split by categories
     * @type {Array<DisaggregationCategoryTotal>}
     * @memberof DisaggregationResponseModel
     */
    categories?: Array<DisaggregationCategoryTotal>;
    /**
     * Insights based on disaggregation values
     * @type {Array<DisaggregationInsight>}
     * @memberof DisaggregationResponseModel
     */
    insights?: Array<DisaggregationInsight>;
}

/**
* @export
* @enum {string}
*/
export enum DisaggregationResponseModelTypeEnum {
    Total = 'total',
    Electricity = 'electricity',
    Gas = 'gas'
}

/**
 * 
 * @export
 * @interface DisaggregationTotal
 */
export interface DisaggregationTotal {
    /**
     * Total consumption in kWh
     * @type {number}
     * @memberof DisaggregationTotal
     */
    energy?: number;
    /**
     * Total cost
     * @type {number}
     * @memberof DisaggregationTotal
     */
    cost?: number;
}
/**
 * 
 * @export
 * @interface DynamicPriceChargingModel
 */
export interface DynamicPriceChargingModel {
    /**
     * If charging is switched on or off
     * @type {boolean}
     * @memberof DynamicPriceChargingModel
     */
    active: boolean;
    /**
     * The timezone which this site is scheduled in. Will be set by the backend to the sites timezone
     * @type {string}
     * @memberof DynamicPriceChargingModel
     */
    timezone?: string;
    /**
     * The periods which this site will schedule charging with this mode. The cheapest 2+ hour timeframes
     * @type {Array<SchedulingTimeModel>}
     * @memberof DynamicPriceChargingModel
     */
    schedulingTimes?: Array<SchedulingTimeModel>;
}
/**
 * 
 * @export
 * @interface EfficiencyCheckSectionResponseModel
 */
export interface EfficiencyCheckSectionResponseModel {
    /**
     * Efficiency Check Value
     * @type {number}
     * @memberof EfficiencyCheckSectionResponseModel
     */
    effCheck?: number;
}
/**
 * 
 * @export
 * @interface EfficiencyCheckV2ResponseModel
 */
export interface EfficiencyCheckV2ResponseModel {
    /**
     * 
     * @type {string}
     * @memberof EfficiencyCheckV2ResponseModel
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof EfficiencyCheckV2ResponseModel
     */
    to?: string;
    /**
     * Efficiency Check Value
     * @type {number}
     * @memberof EfficiencyCheckV2ResponseModel
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface ElectricCarChargingModeCommandModelV1
 */
export interface ElectricCarChargingModeCommandModelV1 {
    /**
     * 
     * @type {string}
     * @memberof ElectricCarChargingModeCommandModelV1
     */
    action: ElectricCarChargingModeCommandModelV1ActionEnum;
}

/**
* @export
* @enum {string}
*/
export enum ElectricCarChargingModeCommandModelV1ActionEnum {
    Start = 'START',
    Stop = 'STOP'
}

/**
 * 
 * @export
 * @interface ElectricCarConfigurationModelV1
 */
export interface ElectricCarConfigurationModelV1 {
    /**
     * 
     * @type {string}
     * @memberof ElectricCarConfigurationModelV1
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ElectricCarConfigurationModelV1
     */
    vendor: string;
    /**
     * 
     * @type {EnodeResponseInformationModel}
     * @memberof ElectricCarConfigurationModelV1
     */
    information: EnodeResponseInformationModel;
    /**
     * 
     * @type {boolean}
     * @memberof ElectricCarConfigurationModelV1
     */
    isAddressSet: boolean;
    /**
     * 
     * @type {string}
     * @memberof ElectricCarConfigurationModelV1
     */
    readingStartDate?: string;
}
/**
 * 
 * @export
 * @interface ElectricCarControlChargingResponseModelV1
 */
export interface ElectricCarControlChargingResponseModelV1 {
    /**
     * 
     * @type {string}
     * @memberof ElectricCarControlChargingResponseModelV1
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ElectricCarControlChargingResponseModelV1
     */
    state: ElectricCarControlChargingResponseModelV1StateEnum;
    /**
     * 
     * @type {ElectricCarControlChargingResponseModelV1FailureReason}
     * @memberof ElectricCarControlChargingResponseModelV1
     */
    failureReason: ElectricCarControlChargingResponseModelV1FailureReason | null;
}

/**
* @export
* @enum {string}
*/
export enum ElectricCarControlChargingResponseModelV1StateEnum {
    Pending = 'PENDING',
    Confirmed = 'CONFIRMED',
    Failed = 'FAILED',
    Cancelled = 'CANCELLED'
}

/**
 * @type ElectricCarControlChargingResponseModelV1FailureReason
 * 
 * @export
 */
export type ElectricCarControlChargingResponseModelV1FailureReason = ControlChargingFailureReason;
/**
 * 
 * @export
 * @interface ElectricCarDeviceGatewayModel
 */
export interface ElectricCarDeviceGatewayModel {
    /**
     * 
     * @type {string}
     * @memberof ElectricCarDeviceGatewayModel
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ElectricCarDeviceGatewayModel
     */
    identifier?: string;
    /**
     * 
     * @type {ElectricCarGatewayClientAccessModel}
     * @memberof ElectricCarDeviceGatewayModel
     */
    clientAccess?: ElectricCarGatewayClientAccessModel;
}
/**
 * 
 * @export
 * @interface ElectricCarGatewayClientAccessModel
 */
export interface ElectricCarGatewayClientAccessModel {
    /**
     * 
     * @type {string}
     * @memberof ElectricCarGatewayClientAccessModel
     */
    tokenExpiryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ElectricCarGatewayClientAccessModel
     */
    privateAccessToken?: string;
}
/**
 * 
 * @export
 * @interface ElectricCarHistorySessionV2CalculatedResponseModel
 */
export interface ElectricCarHistorySessionV2CalculatedResponseModel {
    /**
     * Session Id
     * @type {string}
     * @memberof ElectricCarHistorySessionV2CalculatedResponseModel
     */
    session?: string;
    /**
     * 2018-05-11T00:00:00+01:00
     * @type {string}
     * @memberof ElectricCarHistorySessionV2CalculatedResponseModel
     */
    start?: string;
    /**
     * 2018-05-11T00:00:00+01:00
     * @type {string}
     * @memberof ElectricCarHistorySessionV2CalculatedResponseModel
     */
    finish?: string;
    /**
     * Value in Wh
     * @type {number}
     * @memberof ElectricCarHistorySessionV2CalculatedResponseModel
     */
    energyConsumption?: number;
    /**
     * Duration in seconds
     * @type {number}
     * @memberof ElectricCarHistorySessionV2CalculatedResponseModel
     */
    duration?: number;
    /**
     * Acutal cost in kw/h
     * @type {number}
     * @memberof ElectricCarHistorySessionV2CalculatedResponseModel
     */
    actualCost?: number;
    /**
     * Max cost in kw/h
     * @type {number}
     * @memberof ElectricCarHistorySessionV2CalculatedResponseModel
     */
    maxCost?: number;
}
/**
 * 
 * @export
 * @interface ElectricCarHistorySessionV2ResponseModel
 */
export interface ElectricCarHistorySessionV2ResponseModel {
    /**
     * Session Id
     * @type {string}
     * @memberof ElectricCarHistorySessionV2ResponseModel
     */
    session?: string;
    /**
     * 2018-05-11T00:00:00+01:00
     * @type {string}
     * @memberof ElectricCarHistorySessionV2ResponseModel
     */
    start?: string;
    /**
     * 2018-05-11T00:00:00+01:00
     * @type {string}
     * @memberof ElectricCarHistorySessionV2ResponseModel
     */
    finish?: string;
    /**
     * Value in Wh
     * @type {number}
     * @memberof ElectricCarHistorySessionV2ResponseModel
     */
    energyConsumption?: number;
    /**
     * Duration in seconds
     * @type {number}
     * @memberof ElectricCarHistorySessionV2ResponseModel
     */
    duration?: number;
}
/**
 * 
 * @export
 * @interface ElectricCarHistoryV2ResponseModel
 */
export interface ElectricCarHistoryV2ResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ElectricCarHistoryV2ResponseModel
     */
    timestamp?: string;
    /**
     * Value in Wh
     * @type {number}
     * @memberof ElectricCarHistoryV2ResponseModel
     */
    energyConsumption?: number;
    /**
     * Value in W
     * @type {number}
     * @memberof ElectricCarHistoryV2ResponseModel
     */
    powerConsumption?: number;
    /**
     * The timeline indicates the comparable data to the previous -x days. Depending on the selected resolution it will return different timelines e.g. `PT15M` = -1 days, `PT1H` = -1 days, `P1D` = -7 days, `P1M` = -30 days
     * @type {string}
     * @memberof ElectricCarHistoryV2ResponseModel
     */
    timeline?: ElectricCarHistoryV2ResponseModelTimelineEnum;
}

/**
* @export
* @enum {string}
*/
export enum ElectricCarHistoryV2ResponseModelTimelineEnum {
    Currently = 'currently',
    _1Days = '-1 days',
    _7Days = '-7 days',
    _30Days = '-30 days'
}

/**
 * 
 * @export
 * @interface ElectricCarResponseModelV1
 */
export interface ElectricCarResponseModelV1 {
    /**
     * 
     * @type {string}
     * @memberof ElectricCarResponseModelV1
     */
    configId?: string;
    /**
     * 
     * @type {string}
     * @memberof ElectricCarResponseModelV1
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ElectricCarResponseModelV1
     */
    deviceType?: string;
    /**
     * 
     * @type {ElectricCarConfigurationModelV1}
     * @memberof ElectricCarResponseModelV1
     */
    deviceConfiguration?: ElectricCarConfigurationModelV1;
    /**
     * 
     * @type {ElectricCarDeviceGatewayModel}
     * @memberof ElectricCarResponseModelV1
     */
    deviceGateway?: ElectricCarDeviceGatewayModel;
}
/**
 * 
 * @export
 * @interface EnergyHistoryV2ResponseModel
 */
export interface EnergyHistoryV2ResponseModel {
    /**
     * 
     * @type {string}
     * @memberof EnergyHistoryV2ResponseModel
     */
    timestamp?: string;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    generation?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    consumption?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    carCharging?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    electricCarCharging?: number;
    /**
     * value in %
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    selfSufficiency?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    pvGeneration?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    toBattery?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    fromBattery?: number;
    /**
     * value in %
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    soc?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    toGrid?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    fromGrid?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    pvToGrid?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    pvToBattery?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    selfConsumption?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    consumptionFromGrid?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    consumptionFromBattery?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    consumptionFromPv?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    fromGridTariff?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    fromGridCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyHistoryV2ResponseModel
     */
    missingDataPoints?: number;
    /**
     * The timeline indicates the comparable data to the previous -x days. Depending on the selected resolution it will return different timelines e.g. `PT15M` = -1 days, `PT1H` = -1 days, `P1D` = -7 days, `P1M` = -30 days
     * @type {string}
     * @memberof EnergyHistoryV2ResponseModel
     */
    timeline?: EnergyHistoryV2ResponseModelTimelineEnum;
}

/**
* @export
* @enum {string}
*/
export enum EnergyHistoryV2ResponseModelTimelineEnum {
    Currently = 'currently',
    _1Days = '-1 days',
    _7Days = '-7 days',
    _30Days = '-30 days'
}

/**
 * 
 * @export
 * @interface EnodeResponseInformationModel
 */
export interface EnodeResponseInformationModel {
    /**
     * 
     * @type {string}
     * @memberof EnodeResponseInformationModel
     */
    vin: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnodeResponseInformationModel
     */
    brand: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnodeResponseInformationModel
     */
    model: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnodeResponseInformationModel
     */
    year: number | null;
}
/**
 * 
 * @export
 * @interface EvChargingHistoryAggregatedResponseModel
 */
export interface EvChargingHistoryAggregatedResponseModel {
    /**
     * 
     * @type {string}
     * @memberof EvChargingHistoryAggregatedResponseModel
     */
    timestamp?: string;
    /**
     * 
     * @type {EvSectionResponseModel}
     * @memberof EvChargingHistoryAggregatedResponseModel
     */
    values?: EvSectionResponseModel;
}
/**
 * 
 * @export
 * @interface EvSectionResponseModel
 */
export interface EvSectionResponseModel {
    /**
     * Value in W (avg)
     * @type {number}
     * @memberof EvSectionResponseModel
     */
    home2EvPower?: number;
    /**
     * Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof EvSectionResponseModel
     */
    energyHome2EVSession?: number;
    /**
     * Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof EvSectionResponseModel
     */
    hemsCustomValuesEnergyHome2EVTotal?: number;
}
/**
 * 
 * @export
 * @interface EventNotificationModel
 */
export interface EventNotificationModel {
    /**
     * 
     * @type {string}
     * @memberof EventNotificationModel
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof EventNotificationModel
     */
    creationTime: number;
    /**
     * 
     * @type {string}
     * @memberof EventNotificationModel
     */
    creationTimeISO: string;
    /**
     * 
     * @type {string}
     * @memberof EventNotificationModel
     */
    siteId: string;
    /**
     * 
     * @type {string}
     * @memberof EventNotificationModel
     */
    deviceType: string;
    /**
     * 
     * @type {string}
     * @memberof EventNotificationModel
     */
    messageType?: string;
    /**
     * 
     * @type {string}
     * @memberof EventNotificationModel
     */
    messageSubType?: string;
    /**
     * 
     * @type {string}
     * @memberof EventNotificationModel
     */
    deviceId?: string;
    /**
     * 
     * @type {NotificationModel}
     * @memberof EventNotificationModel
     */
    data?: NotificationModel;
}
/**
 * 
 * @export
 * @interface Forbidden
 */
export interface Forbidden {
    /**
     * The error name
     * @type {string}
     * @memberof Forbidden
     */
    name: string;
    /**
     * An error message
     * @type {string}
     * @memberof Forbidden
     */
    message: string;
    /**
     * The status code of the exception
     * @type {number}
     * @memberof Forbidden
     */
    status: number;
    /**
     * A list of related errors
     * @type {Array<GenericError>}
     * @memberof Forbidden
     */
    errors?: Array<GenericError>;
    /**
     * The stack trace (only in development mode)
     * @type {string}
     * @memberof Forbidden
     */
    stack?: string;
}
/**
 * 
 * @export
 * @interface ForecastEfficiencyCheckModel
 */
export interface ForecastEfficiencyCheckModel {
    /**
     * 
     * @type {string}
     * @memberof ForecastEfficiencyCheckModel
     */
    timestamp?: string;
    /**
     * 
     * @type {EfficiencyCheckSectionResponseModel}
     * @memberof ForecastEfficiencyCheckModel
     */
    values?: EfficiencyCheckSectionResponseModel;
}
/**
 * 
 * @export
 * @interface ForecastResponseValuesModel
 */
export interface ForecastResponseValuesModel {
    /**
     * Power value in W
     * @type {number}
     * @memberof ForecastResponseValuesModel
     */
    pVpower?: number;
    /**
     * Incremental value in Wh
     * @type {number}
     * @memberof ForecastResponseValuesModel
     */
    pVenergyOut?: number;
}
/**
 * 
 * @export
 * @interface ForecastValuesResponseModel
 */
export interface ForecastValuesResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ForecastValuesResponseModel
     */
    timestamp?: string;
    /**
     * 
     * @type {ForecastValuesResponseValuesModel}
     * @memberof ForecastValuesResponseModel
     */
    values?: ForecastValuesResponseValuesModel;
}
/**
 * 
 * @export
 * @interface ForecastValuesResponseValuesModel
 */
export interface ForecastValuesResponseValuesModel {
    /**
     * Power value in W (first)
     * @type {number}
     * @memberof ForecastValuesResponseValuesModel
     */
    pVpower?: number;
    /**
     * Cumulative value in Wh (first)
     * @type {number}
     * @memberof ForecastValuesResponseValuesModel
     */
    pVenergyOutTotal?: number;
}
/**
 * 
 * @export
 * @interface GasHistoryV2ResponseModel
 */
export interface GasHistoryV2ResponseModel {
    /**
     * 
     * @type {string}
     * @memberof GasHistoryV2ResponseModel
     */
    timestamp?: string;
    /**
     * 
     * @type {number}
     * @memberof GasHistoryV2ResponseModel
     */
    fromGrid?: number;
    /**
     * 
     * @type {number}
     * @memberof GasHistoryV2ResponseModel
     */
    fromGridTariff?: number;
    /**
     * 
     * @type {number}
     * @memberof GasHistoryV2ResponseModel
     */
    fromGridCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof GasHistoryV2ResponseModel
     */
    missingDataPoints?: number;
    /**
     * The timeline indicates the comparable data to the previous -x days. Depending on the selected resolution it will return different timelines e.g. `PT15M` = -1 days, `PT1H` = -1 days, `P1D` = -7 days, `P1M` = -30 days
     * @type {string}
     * @memberof GasHistoryV2ResponseModel
     */
    timeline?: GasHistoryV2ResponseModelTimelineEnum;
}

/**
* @export
* @enum {string}
*/
export enum GasHistoryV2ResponseModelTimelineEnum {
    Currently = 'currently',
    _1Days = '-1 days',
    _7Days = '-7 days',
    _30Days = '-30 days'
}

/**
 * 
 * @export
 * @interface GatewayDeviceConfiguration
 */
export interface GatewayDeviceConfiguration {
    /**
     * GridX unique identifier for a particular scan process
     * @type {string}
     * @memberof GatewayDeviceConfiguration
     */
    scanId?: string;
    /**
     * GridX timestamp of the scan action
     * @type {string}
     * @memberof GatewayDeviceConfiguration
     */
    lastScanDate?: string;
    /**
     * Describes the state of the scanning process
     * @type {string}
     * @memberof GatewayDeviceConfiguration
     */
    scanningState?: GatewayDeviceConfigurationScanningStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum GatewayDeviceConfigurationScanningStateEnum {
    Pending = 'pending',
    Done = 'done',
    Failed = 'failed'
}

/**
 * 
 * @export
 * @interface GatewayDeviceInstallation
 */
export interface GatewayDeviceInstallation {
    /**
     * 
     * @type {string}
     * @memberof GatewayDeviceInstallation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayDeviceInstallation
     */
    deviceType?: GatewayDeviceInstallationDeviceTypeEnum;
    /**
     * 
     * @type {GatewayDeviceConfiguration}
     * @memberof GatewayDeviceInstallation
     */
    deviceConfiguration?: GatewayDeviceConfiguration;
    /**
     * 
     * @type {object}
     * @memberof GatewayDeviceInstallation
     */
    deviceGateway?: object;
}

/**
* @export
* @enum {string}
*/
export enum GatewayDeviceInstallationDeviceTypeEnum {
    Gateway = 'gateway'
}

/**
 * 
 * @export
 * @interface GatewayRequestModel
 */
export interface GatewayRequestModel {
    /**
     * Box printed code
     * @type {string}
     * @memberof GatewayRequestModel
     */
    startcode: string;
    /**
     * Vendor name
     * @type {string}
     * @memberof GatewayRequestModel
     */
    vendorName?: GatewayRequestModelVendorNameEnum;
}

/**
* @export
* @enum {string}
*/
export enum GatewayRequestModelVendorNameEnum {
    Gridx = 'gridx'
}

/**
 * 
 * @export
 * @interface GcpSectionResponseModel
 */
export interface GcpSectionResponseModel {
    /**
     * Value in W (avg)
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    power?: number;
    /**
     * Value in W (avg)
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    powerConsumption?: number;
    /**
     * Value in W (avg)
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    powerSelfConsumption?: number;
    /**
     * Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    energyGrid2home?: number;
    /**
     * Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    energyHome2grid?: number;
    /**
     * Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    energyConsumption?: number;
    /**
     * Direct consumption from PV cells. Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    energyConsumptionFromPV?: number;
    /**
     * Consumed from grid import. Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    energyConsumptionFromGrid?: number;
    /**
     * Consumed from discharging battery. Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    energyConsumptionFromBattery?: number;
    /**
     * Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    energySelfConsumption?: number;
    /**
     * Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    pvEfficiencyProduction?: number;
    /**
     * Percentage of PV efficiency
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    pvEfficiencyRatio?: number;
    /**
     * Quality of efficiency
     * @type {string}
     * @memberof GcpSectionResponseModel
     */
    pvEfficiencyQuality?: string;
    /**
     * Unix timestamp of efficiency
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    pvEfficiencyReadingDate?: number;
    /**
     * Solarcloud balance
     * @type {number}
     * @memberof GcpSectionResponseModel
     */
    solarCloudBalance?: number;
}
/**
 * 
 * @export
 * @interface GenericError
 */
export interface GenericError {
    [key: string]: any | any;
    /**
     * The error name
     * @type {string}
     * @memberof GenericError
     */
    name: string;
    /**
     * An error message
     * @type {string}
     * @memberof GenericError
     */
    message: string;
}
/**
 * 
 * @export
 * @interface GenericSmartChargingModel
 */
export interface GenericSmartChargingModel {
    /**
     * The scheduling mode to use.
     * @type {string}
     * @memberof GenericSmartChargingModel
     */
    mode: GenericSmartChargingModelModeEnum;
    /**
     * Parameters needed for the specific charging mode
     * @type {object}
     * @memberof GenericSmartChargingModel
     */
    parameters: object;
}

/**
* @export
* @enum {string}
*/
export enum GenericSmartChargingModelModeEnum {
    Schedule = 'schedule',
    GridFriendlyCharging = 'gridFriendlyCharging',
    SolarAssistedCharging = 'solarAssistedCharging',
    DynamicPriceCharging = 'dynamicPriceCharging',
    HomePlugAndCharge = 'homePlugAndCharge',
    Autopilot = 'autopilot',
    ProgramCharging = 'programCharging',
    SafetyCharging = 'safetyCharging'
}

/**
 * 
 * @export
 * @interface GoogleAPILocationRequestModel
 */
export interface GoogleAPILocationRequestModel {
    /**
     * 
     * @type {string}
     * @memberof GoogleAPILocationRequestModel
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleAPILocationRequestModel
     */
    houseNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleAPILocationRequestModel
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleAPILocationRequestModel
     */
    zipCode: string;
}
/**
 * 
 * @export
 * @interface GridConnectionPointHistoryAggregationResponseModel
 */
export interface GridConnectionPointHistoryAggregationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof GridConnectionPointHistoryAggregationResponseModel
     */
    timestamp?: string;
    /**
     * 
     * @type {GcpSectionResponseModel}
     * @memberof GridConnectionPointHistoryAggregationResponseModel
     */
    values?: GcpSectionResponseModel;
}
/**
 * 
 * @export
 * @interface GvsUserInfoResponseModel
 */
export interface GvsUserInfoResponseModel {
    /**
     * GVS customer flag
     * @type {boolean}
     * @memberof GvsUserInfoResponseModel
     */
    isGvsCustomer: boolean;
    /**
     * 
     * @type {string}
     * @memberof GvsUserInfoResponseModel
     */
    gvsRedeemedAmount?: string;
}
/**
 * 
 * @export
 * @interface HeatingCapabilitySet
 */
export interface HeatingCapabilitySet {
    /**
     * 
     * @type {NumberRange}
     * @memberof HeatingCapabilitySet
     */
    celsius?: NumberRange;
}
/**
 * 
 * @export
 * @interface HeatingDeviceCapabilities
 */
export interface HeatingDeviceCapabilities {
    /**
     * 
     * @type {Array<string>}
     * @memberof HeatingDeviceCapabilities
     */
    read?: Array<HeatingDeviceCapabilitiesReadEnum>;
    /**
     * 
     * @type {HeatingDeviceWriteCapabilities}
     * @memberof HeatingDeviceCapabilities
     */
    write?: HeatingDeviceWriteCapabilities;
}

/**
* @export
* @enum {string}
*/
export enum HeatingDeviceCapabilitiesReadEnum {
    InsideTemperature = 'insideTemperature',
    Humidity = 'humidity'
}

/**
 * 
 * @export
 * @interface HeatingDeviceWriteCapabilities
 */
export interface HeatingDeviceWriteCapabilities {
    /**
     * 
     * @type {string}
     * @memberof HeatingDeviceWriteCapabilities
     */
    power?: HeatingDeviceWriteCapabilitiesPowerEnum;
    /**
     * 
     * @type {HeatingCapabilitySet}
     * @memberof HeatingDeviceWriteCapabilities
     */
    temperatures?: HeatingCapabilitySet;
}

/**
* @export
* @enum {string}
*/
export enum HeatingDeviceWriteCapabilitiesPowerEnum {
    On = 'ON',
    Off = 'OFF'
}

/**
 * 
 * @export
 * @interface HvacDeviceModel
 */
export interface HvacDeviceModel {
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModel
     */
    configId?: string;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModel
     */
    type?: HvacDeviceModelTypeEnum;
    /**
     * 
     * @type {HvacDeviceModelCapabilities}
     * @memberof HvacDeviceModel
     */
    capabilities?: HvacDeviceModelCapabilities;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModel
     */
    vendor?: HvacDeviceModelVendorEnum;
}

/**
* @export
* @enum {string}
*/
export enum HvacDeviceModelTypeEnum {
    Heating = 'HEATING',
    AirConditioning = 'AIR_CONDITIONING',
    HotWater = 'HOT_WATER'
}
/**
* @export
* @enum {string}
*/
export enum HvacDeviceModelVendorEnum {
    Tado = 'tado'
}

/**
 * @type HvacDeviceModelCapabilities
 * 
 * @export
 */
export type HvacDeviceModelCapabilities = AirConditioningDeviceCapabilities | HeatingDeviceCapabilities;
/**
 * @type HvacDeviceModifyModel
 * 
 * @export
 */
export type HvacDeviceModifyModel = HvacDeviceModifyModelOneOf | HvacDeviceModifyModelOneOf1 | HvacDeviceModifyModelOneOf2 | HvacDeviceModifyModelOneOf3 | HvacDeviceModifyModelOneOf4;
/**
 * 
 * @export
 * @interface HvacDeviceModifyModelOneOf
 */
export interface HvacDeviceModifyModelOneOf {
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf
     */
    command: HvacDeviceModifyModelOneOfCommandEnum;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf
     */
    value: HvacDeviceModifyModelOneOfValueEnum;
}

/**
* @export
* @enum {string}
*/
export enum HvacDeviceModifyModelOneOfCommandEnum {
    Mode = 'mode',
    Swings = 'swings',
    FanSpeeds = 'fanSpeeds',
    Temperatures = 'temperatures',
    Power = 'power'
}
/**
* @export
* @enum {string}
*/
export enum HvacDeviceModifyModelOneOfValueEnum {
    Heat = 'HEAT',
    Cool = 'COOL',
    On = 'ON',
    Off = 'OFF',
    Low = 'LOW',
    Middle = 'MIDDLE',
    High = 'HIGH',
    Auto = 'AUTO'
}

/**
 * 
 * @export
 * @interface HvacDeviceModifyModelOneOf1
 */
export interface HvacDeviceModifyModelOneOf1 {
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf1
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf1
     */
    command: HvacDeviceModifyModelOneOf1CommandEnum;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf1
     */
    value: HvacDeviceModifyModelOneOf1ValueEnum;
}

/**
* @export
* @enum {string}
*/
export enum HvacDeviceModifyModelOneOf1CommandEnum {
    Swings = 'swings'
}
/**
* @export
* @enum {string}
*/
export enum HvacDeviceModifyModelOneOf1ValueEnum {
    On = 'ON',
    Off = 'OFF'
}

/**
 * 
 * @export
 * @interface HvacDeviceModifyModelOneOf2
 */
export interface HvacDeviceModifyModelOneOf2 {
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf2
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf2
     */
    command: HvacDeviceModifyModelOneOf2CommandEnum;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf2
     */
    value: HvacDeviceModifyModelOneOf2ValueEnum;
}

/**
* @export
* @enum {string}
*/
export enum HvacDeviceModifyModelOneOf2CommandEnum {
    FanSpeeds = 'fanSpeeds'
}
/**
* @export
* @enum {string}
*/
export enum HvacDeviceModifyModelOneOf2ValueEnum {
    Low = 'LOW',
    Middle = 'MIDDLE',
    High = 'HIGH',
    Auto = 'AUTO'
}

/**
 * 
 * @export
 * @interface HvacDeviceModifyModelOneOf3
 */
export interface HvacDeviceModifyModelOneOf3 {
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf3
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf3
     */
    command: HvacDeviceModifyModelOneOf3CommandEnum;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf3
     */
    value: string;
}

/**
* @export
* @enum {string}
*/
export enum HvacDeviceModifyModelOneOf3CommandEnum {
    Temperatures = 'temperatures'
}

/**
 * 
 * @export
 * @interface HvacDeviceModifyModelOneOf4
 */
export interface HvacDeviceModifyModelOneOf4 {
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf4
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf4
     */
    command: HvacDeviceModifyModelOneOf4CommandEnum;
    /**
     * 
     * @type {string}
     * @memberof HvacDeviceModifyModelOneOf4
     */
    value: HvacDeviceModifyModelOneOf4ValueEnum;
}

/**
* @export
* @enum {string}
*/
export enum HvacDeviceModifyModelOneOf4CommandEnum {
    Power = 'power'
}
/**
* @export
* @enum {string}
*/
export enum HvacDeviceModifyModelOneOf4ValueEnum {
    On = 'ON',
    Off = 'OFF'
}

/**
 * 
 * @export
 * @interface HvacHistoryAggregationResponseModel
 */
export interface HvacHistoryAggregationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof HvacHistoryAggregationResponseModel
     */
    timestamp?: string;
    /**
     * 
     * @type {HvacSectionResponseModel}
     * @memberof HvacHistoryAggregationResponseModel
     */
    values?: HvacSectionResponseModel;
}
/**
 * 
 * @export
 * @interface HvacSectionResponseModel
 */
export interface HvacSectionResponseModel {
    /**
     * Value in C° (avg)
     * @type {number}
     * @memberof HvacSectionResponseModel
     */
    temperatureC?: number;
}
/**
 * 
 * @export
 * @interface InternalServerError
 */
export interface InternalServerError {
    /**
     * The error name
     * @type {string}
     * @memberof InternalServerError
     */
    name: string;
    /**
     * An error message
     * @type {string}
     * @memberof InternalServerError
     */
    message: string;
    /**
     * The status code of the exception
     * @type {number}
     * @memberof InternalServerError
     */
    status: number;
    /**
     * A list of related errors
     * @type {Array<GenericError>}
     * @memberof InternalServerError
     */
    errors?: Array<GenericError>;
    /**
     * The stack trace (only in development mode)
     * @type {string}
     * @memberof InternalServerError
     */
    stack?: string;
}
/**
 * 
 * @export
 * @interface InverterModel
 */
export interface InverterModel {
    /**
     * 
     * @type {boolean}
     * @memberof InverterModel
     */
    installed?: boolean;
    /**
     * Value in Wh
     * @type {number}
     * @memberof InverterModel
     */
    maxPower?: number;
    /**
     * Value in W
     * @type {number}
     * @memberof InverterModel
     */
    pvPeakPower?: number;
    /**
     * 
     * @type {string}
     * @memberof InverterModel
     */
    provider: string;
    /**
     * 
     * @type {string}
     * @memberof InverterModel
     */
    model: string;
    /**
     * 
     * @type {boolean}
     * @memberof InverterModel
     */
    gcp?: boolean;
}
/**
 * 
 * @export
 * @interface IotPairingModel
 */
export interface IotPairingModel {
    /**
     * 
     * @type {boolean}
     * @memberof IotPairingModel
     */
    paired?: boolean;
    /**
     * Name of the vendor
     * @type {string}
     * @memberof IotPairingModel
     */
    name?: string;
    /**
     * Display name of the vendor
     * @type {string}
     * @memberof IotPairingModel
     */
    displayName?: string;
    /**
     * Date linked (null if not linked), unix timestamp in seconds
     * @type {number}
     * @memberof IotPairingModel
     */
    createdOn?: number;
}
/**
 * 
 * @export
 * @interface IotPairingModifyModel
 */
export interface IotPairingModifyModel {
    /**
     * 
     * @type {string}
     * @memberof IotPairingModifyModel
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof IotPairingModifyModel
     */
    state: string;
    /**
     * Optional parameter to enable the Virta pairing api flow, as scanned from QR code
     * @type {string}
     * @memberof IotPairingModifyModel
     */
    pairingCode?: string;
}
/**
 * 
 * @export
 * @interface IotPairingVendorUrlModel
 */
export interface IotPairingVendorUrlModel {
    /**
     * 
     * @type {string}
     * @memberof IotPairingVendorUrlModel
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof IotPairingVendorUrlModel
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof IotPairingVendorUrlModel
     */
    linkToken?: string;
}
/**
 * 
 * @export
 * @interface IsGridXUserResponseModel
 */
export interface IsGridXUserResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof IsGridXUserResponseModel
     */
    isGridX: boolean;
}
/**
 * 
 * @export
 * @interface MeterDeltaResponseModel
 */
export interface MeterDeltaResponseModel {
    /**
     * 
     * @type {string}
     * @memberof MeterDeltaResponseModel
     */
    firstReading?: string;
    /**
     * 
     * @type {string}
     * @memberof MeterDeltaResponseModel
     */
    lastUpdate?: string;
    /**
     * 
     * @type {ValueUnitResponseModel}
     * @memberof MeterDeltaResponseModel
     */
    pvProductionCounterDelta?: ValueUnitResponseModel;
    /**
     * 
     * @type {ValueUnitResponseModel}
     * @memberof MeterDeltaResponseModel
     */
    batteryInCounterDelta?: ValueUnitResponseModel;
    /**
     * 
     * @type {ValueUnitResponseModel}
     * @memberof MeterDeltaResponseModel
     */
    batteryOutCounterDelta?: ValueUnitResponseModel;
    /**
     * 
     * @type {ValueUnitResponseModel}
     * @memberof MeterDeltaResponseModel
     */
    gridInCounterDelta?: ValueUnitResponseModel;
    /**
     * 
     * @type {ValueUnitResponseModel}
     * @memberof MeterDeltaResponseModel
     */
    gridOutCounterDelta?: ValueUnitResponseModel;
    /**
     * 
     * @type {ValueUnitResponseModel}
     * @memberof MeterDeltaResponseModel
     */
    consumptionCounterDelta?: ValueUnitResponseModel;
}
/**
 * 
 * @export
 * @interface MeterDeviceConfigurationModel
 */
export interface MeterDeviceConfigurationModel {
    /**
     * 
     * @type {boolean}
     * @memberof MeterDeviceConfigurationModel
     */
    billed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeterDeviceConfigurationModel
     */
    manufacturer?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeterDeviceConfigurationModel
     */
    gcp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeterDeviceConfigurationModel
     */
    readingStartDate?: string;
}
/**
 * 
 * @export
 * @interface MeterDeviceModel
 */
export interface MeterDeviceModel {
    /**
     * 
     * @type {string}
     * @memberof MeterDeviceModel
     */
    configId?: string;
    /**
     * 
     * @type {string}
     * @memberof MeterDeviceModel
     */
    creationTime: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeterDeviceModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof MeterDeviceModel
     */
    deviceType?: MeterDeviceModelDeviceTypeEnum;
    /**
     * 
     * @type {MeterDeviceConfigurationModel}
     * @memberof MeterDeviceModel
     */
    deviceConfiguration?: MeterDeviceConfigurationModel;
    /**
     * 
     * @type {PvBatteryDeviceGatewayModel}
     * @memberof MeterDeviceModel
     */
    deviceGateway?: PvBatteryDeviceGatewayModel;
}

/**
* @export
* @enum {string}
*/
export enum MeterDeviceModelDeviceTypeEnum {
    Meter = 'Meter'
}

/**
 * 
 * @export
 * @interface MeterSurveyRequestModel
 */
export interface MeterSurveyRequestModel {
    /**
     * 
     * @type {string}
     * @memberof MeterSurveyRequestModel
     */
    houseType?: MeterSurveyRequestModelHouseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MeterSurveyRequestModel
     */
    homeOwnership?: MeterSurveyRequestModelHomeOwnershipEnum;
    /**
     * 
     * @type {number}
     * @memberof MeterSurveyRequestModel
     */
    houseHoldSize?: number;
    /**
     * 
     * @type {number}
     * @memberof MeterSurveyRequestModel
     */
    bedrooms?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MeterSurveyRequestModel
     */
    products?: Array<MeterSurveyRequestModelProductsEnum>;
    /**
     * 
     * @type {string}
     * @memberof MeterSurveyRequestModel
     */
    homeHeating?: MeterSurveyRequestModelHomeHeatingEnum;
    /**
     * 
     * @type {string}
     * @memberof MeterSurveyRequestModel
     */
    waterHeating?: MeterSurveyRequestModelWaterHeatingEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MeterSurveyRequestModel
     */
    hotWaterTank?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeterSurveyRequestModel
     */
    oldBoiler?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MeterSurveyRequestModel
     */
    homeInsulation?: Array<MeterSurveyRequestModelHomeInsulationEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof MeterSurveyRequestModel
     */
    smartThermostat?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MeterSurveyRequestModel
     */
    yourPriority?: Array<MeterSurveyRequestModelYourPriorityEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof MeterSurveyRequestModel
     */
    marketingConsent?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum MeterSurveyRequestModelHouseTypeEnum {
    Bungalow = 'BUNGALOW',
    DetachedHouse = 'DETACHED_HOUSE',
    Flat = 'FLAT',
    TerracedHouse = 'TERRACED_HOUSE',
    SemiDetachedHouse = 'SEMI_DETACHED_HOUSE'
}
/**
* @export
* @enum {string}
*/
export enum MeterSurveyRequestModelHomeOwnershipEnum {
    Rent = 'RENT',
    Own = 'OWN'
}
/**
* @export
* @enum {string}
*/
export enum MeterSurveyRequestModelProductsEnum {
    Solar = 'SOLAR',
    SolarBattery = 'SOLAR_BATTERY',
    ElectricVehicle = 'ELECTRIC_VEHICLE',
    EvChargePoint = 'EV_CHARGE_POINT',
    AirSourceHeatPump = 'AIR_SOURCE_HEAT_PUMP',
    GroundSourceHeatPump = 'GROUND_SOURCE_HEAT_PUMP'
}
/**
* @export
* @enum {string}
*/
export enum MeterSurveyRequestModelHomeHeatingEnum {
    GasBoiler = 'GAS_BOILER',
    ElectricStorageHeater = 'ELECTRIC_STORAGE_HEATER',
    OilBoiler = 'OIL_BOILER',
    LpgBoiler = 'LPG_BOILER',
    Coal = 'COAL',
    AirSourceHeatPump = 'AIR_SOURCE_HEAT_PUMP',
    GroundSourceHeatPump = 'GROUND_SOURCE_HEAT_PUMP',
    Others = 'OTHERS'
}
/**
* @export
* @enum {string}
*/
export enum MeterSurveyRequestModelWaterHeatingEnum {
    Gas = 'GAS',
    Electricity = 'ELECTRICITY',
    Oil = 'OIL'
}
/**
* @export
* @enum {string}
*/
export enum MeterSurveyRequestModelHomeInsulationEnum {
    LoftInsulation = 'LOFT_INSULATION',
    CavityWall = 'CAVITY_WALL',
    SolidWall = 'SOLID_WALL',
    DoubleGlazedWindows = 'DOUBLE_GLAZED_WINDOWS',
    Nothing = 'NOTHING'
}
/**
* @export
* @enum {string}
*/
export enum MeterSurveyRequestModelYourPriorityEnum {
    SavingMoneyAndEnergy = 'SAVING_MONEY_AND_ENERGY',
    ReduceCarbon = 'REDUCE_CARBON',
    SelfSufficient = 'SELF_SUFFICIENT',
    ControlAndConvenience = 'CONTROL_AND_CONVENIENCE'
}

/**
 * 
 * @export
 * @interface NameModel
 */
export interface NameModel {
    /**
     * 
     * @type {string}
     * @memberof NameModel
     */
    first?: string;
    /**
     * 
     * @type {string}
     * @memberof NameModel
     */
    last?: string;
}
/**
 * 
 * @export
 * @interface NotFound
 */
export interface NotFound {
    /**
     * The error name
     * @type {string}
     * @memberof NotFound
     */
    name: string;
    /**
     * An error message
     * @type {string}
     * @memberof NotFound
     */
    message: string;
    /**
     * The status code of the exception
     * @type {number}
     * @memberof NotFound
     */
    status: number;
    /**
     * A list of related errors
     * @type {Array<GenericError>}
     * @memberof NotFound
     */
    errors?: Array<GenericError>;
    /**
     * The stack trace (only in development mode)
     * @type {string}
     * @memberof NotFound
     */
    stack?: string;
}
/**
 * 
 * @export
 * @interface NotificationModel
 */
export interface NotificationModel {
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    kind: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationModel
     */
    precedence?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationModel
     */
    lifetime?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationModel
     */
    storageTime?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationModel
     */
    readable: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotificationModel
     */
    timestamp: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationModel
     */
    sent?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    correlationId?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    configId: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationModel
     */
    read?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationModel
     */
    archive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationModel
     */
    keep?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationModifyModel
 */
export interface NotificationModifyModel {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationModifyModel
     */
    read?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationModifyModel
     */
    archive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationModifyModel
     */
    keep?: boolean;
}
/**
 * 
 * @export
 * @interface NumberRange
 */
export interface NumberRange {
    /**
     * 
     * @type {number}
     * @memberof NumberRange
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof NumberRange
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof NumberRange
     */
    step?: number;
}
/**
 * 
 * @export
 * @interface PVBatteryDeviceModel
 */
export interface PVBatteryDeviceModel {
    /**
     * 
     * @type {string}
     * @memberof PVBatteryDeviceModel
     */
    configId?: string;
    /**
     * 
     * @type {string}
     * @memberof PVBatteryDeviceModel
     */
    creationTime?: string;
    /**
     * 
     * @type {string}
     * @memberof PVBatteryDeviceModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof PVBatteryDeviceModel
     */
    deviceType?: PVBatteryDeviceModelDeviceTypeEnum;
    /**
     * 
     * @type {PvBatteryDeviceConfigurationModel}
     * @memberof PVBatteryDeviceModel
     */
    deviceConfiguration?: PvBatteryDeviceConfigurationModel;
    /**
     * 
     * @type {PvBatteryDeviceGatewayModel}
     * @memberof PVBatteryDeviceModel
     */
    deviceGateway?: PvBatteryDeviceGatewayModel;
}

/**
* @export
* @enum {string}
*/
export enum PVBatteryDeviceModelDeviceTypeEnum {
    Inverter = 'Inverter'
}

/**
 * 
 * @export
 * @interface PVBatteryProductModel
 */
export interface PVBatteryProductModel {
    /**
     * 
     * @type {string}
     * @memberof PVBatteryProductModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PVBatteryProductModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PVBatteryProductModel
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof PVBatteryProductModel
     */
    end?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PVBatteryProductModel
     */
    tag?: string;
}
/**
 * 
 * @export
 * @interface PVBatteryResponseModel
 */
export interface PVBatteryResponseModel {
    /**
     * 
     * @type {string}
     * @memberof PVBatteryResponseModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PVBatteryResponseModel
     */
    deviceType?: string;
    /**
     * 
     * @type {string}
     * @memberof PVBatteryResponseModel
     */
    deviceId?: string;
    /**
     * 
     * @type {PvBatteryDeviceConfigurationModel}
     * @memberof PVBatteryResponseModel
     */
    deviceConfiguration?: PvBatteryDeviceConfigurationModel;
    /**
     * 
     * @type {PvBatteryDeviceGatewayModel}
     * @memberof PVBatteryResponseModel
     */
    deviceGateway?: PvBatteryDeviceGatewayModel;
    /**
     * 
     * @type {string}
     * @memberof PVBatteryResponseModel
     */
    vendorName?: string;
    /**
     * 
     * @type {string}
     * @memberof PVBatteryResponseModel
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof PVBatteryResponseModel
     */
    lastModified?: string;
}
/**
 * 
 * @export
 * @interface PlugAndChargeModel
 */
export interface PlugAndChargeModel {
    /**
     * If PlugAndCharge mode is switched on or off
     * @type {boolean}
     * @memberof PlugAndChargeModel
     */
    active: boolean;
}
/**
 * 
 * @export
 * @interface PowerHistoryV2ResponseModel
 */
export interface PowerHistoryV2ResponseModel {
    /**
     * 
     * @type {string}
     * @memberof PowerHistoryV2ResponseModel
     */
    timestamp?: string;
    /**
     * Value in W
     * @type {number}
     * @memberof PowerHistoryV2ResponseModel
     */
    inverter?: number;
    /**
     * Value in W
     * @type {number}
     * @memberof PowerHistoryV2ResponseModel
     */
    grid?: number;
    /**
     * Value in W
     * @type {number}
     * @memberof PowerHistoryV2ResponseModel
     */
    battery?: number;
    /**
     * value in W
     * @type {number}
     * @memberof PowerHistoryV2ResponseModel
     */
    consumption?: number;
    /**
     * value in W
     * @type {number}
     * @memberof PowerHistoryV2ResponseModel
     */
    wallboxConsumption?: number;
    /**
     * value in W
     * @type {number}
     * @memberof PowerHistoryV2ResponseModel
     */
    electricCarConsumption?: number;
    /**
     * value in W
     * @type {number}
     * @memberof PowerHistoryV2ResponseModel
     */
    selfConsumption?: number;
    /**
     * The timeline indicates the comparable data to the previous -x days. Depending on the selected resolution it will return different timelines e.g. `PT15M` = -1 days, `PT1H` = -1 days, `P1D` = -7 days, `P1M` = -30 days
     * @type {string}
     * @memberof PowerHistoryV2ResponseModel
     */
    timeline?: PowerHistoryV2ResponseModelTimelineEnum;
}

/**
* @export
* @enum {string}
*/
export enum PowerHistoryV2ResponseModelTimelineEnum {
    Currently = 'currently',
    _1Days = '-1 days',
    _7Days = '-7 days',
    _30Days = '-30 days'
}

/**
 * 
 * @export
 * @interface PreconditionFailed
 */
export interface PreconditionFailed {
    /**
     * The error name
     * @type {string}
     * @memberof PreconditionFailed
     */
    name: string;
    /**
     * An error message
     * @type {string}
     * @memberof PreconditionFailed
     */
    message: string;
    /**
     * The status code of the exception
     * @type {number}
     * @memberof PreconditionFailed
     */
    status: number;
    /**
     * A list of related errors
     * @type {Array<GenericError>}
     * @memberof PreconditionFailed
     */
    errors?: Array<GenericError>;
    /**
     * The stack trace (only in development mode)
     * @type {string}
     * @memberof PreconditionFailed
     */
    stack?: string;
}
/**
 * 
 * @export
 * @interface PriceModel
 */
export interface PriceModel {
    /**
     * The price amount as local value
     * @type {number}
     * @memberof PriceModel
     */
    price?: number;
    /**
     * The uuid of the given entry
     * @type {string}
     * @memberof PriceModel
     */
    id?: string;
    /**
     * The start time for the current entry, in local time
     * @type {string}
     * @memberof PriceModel
     */
    start?: string;
    /**
     * The end time for the current entry, in local time
     * @type {string}
     * @memberof PriceModel
     */
    end?: string;
}
/**
 * 
 * @export
 * @interface PriceModelV2
 */
export interface PriceModelV2 {
    /**
     * The price amount as local value
     * @type {number}
     * @memberof PriceModelV2
     */
    price: number;
    /**
     * The start time for the current entry, in local time
     * @type {string}
     * @memberof PriceModelV2
     */
    start: string;
    /**
     * The end time for the current entry, in local time
     * @type {string}
     * @memberof PriceModelV2
     */
    end: string;
}
/**
 * 
 * @export
 * @interface ProgramChargingModel
 */
export interface ProgramChargingModel {
    /**
     * If DepartureTimeCharging mode is switched on or off
     * @type {boolean}
     * @memberof ProgramChargingModel
     */
    active: boolean;
    /**
     * The departure time of the EV
     * @type {string}
     * @memberof ProgramChargingModel
     */
    departureTimestamp?: string;
    /**
     * The minimum SoC the EV shall have and keep to guarantee the range for the next usage.
     * @type {number}
     * @memberof ProgramChargingModel
     */
    minSoc?: number;
    /**
     * The State of Charge (SoC) level read and set by the user for the connected EV.
     * @type {number}
     * @memberof ProgramChargingModel
     */
    userSoc?: number;
}
/**
 * 
 * @export
 * @interface PvBatteryAddModel
 */
export interface PvBatteryAddModel {
    /**
     * 
     * @type {string}
     * @memberof PvBatteryAddModel
     */
    serialnumber: string;
    /**
     * 
     * @type {string}
     * @memberof PvBatteryAddModel
     */
    meternumber?: string;
    /**
     * Specify vendorName to lookup devices for a specific vendor. Currently supported fronius, kostal and sungrow. Defaults to fronius for backwards compatibility
     * @type {string}
     * @memberof PvBatteryAddModel
     */
    vendorName?: string;
}
/**
 * 
 * @export
 * @interface PvBatteryCarbonAvoidedResponseModel
 */
export interface PvBatteryCarbonAvoidedResponseModel {
    /**
     * 
     * @type {string}
     * @memberof PvBatteryCarbonAvoidedResponseModel
     */
    timestamp: string;
    /**
     * 
     * @type {CarbonAvoidedSectionResponseModel}
     * @memberof PvBatteryCarbonAvoidedResponseModel
     */
    values: CarbonAvoidedSectionResponseModel;
}
/**
 * 
 * @export
 * @interface PvBatteryCarbonAvoidedTotalResponseModel
 */
export interface PvBatteryCarbonAvoidedTotalResponseModel {
    /**
     * Total value of CO2 in gramm for given timeframe
     * @type {number}
     * @memberof PvBatteryCarbonAvoidedTotalResponseModel
     */
    total: number;
}
/**
 * 
 * @export
 * @interface PvBatteryDeviceConfigurationModel
 */
export interface PvBatteryDeviceConfigurationModel {
    /**
     * 
     * @type {InverterModel}
     * @memberof PvBatteryDeviceConfigurationModel
     */
    inverter?: InverterModel;
    /**
     * 
     * @type {BatteryModel}
     * @memberof PvBatteryDeviceConfigurationModel
     */
    battery?: BatteryModel;
    /**
     * 
     * @type {Array<PVBatteryProductModel>}
     * @memberof PvBatteryDeviceConfigurationModel
     */
    products?: Array<PVBatteryProductModel>;
    /**
     * 
     * @type {string}
     * @memberof PvBatteryDeviceConfigurationModel
     */
    readingStartDate?: string;
}
/**
 * 
 * @export
 * @interface PvBatteryDeviceGatewayModel
 */
export interface PvBatteryDeviceGatewayModel {
    /**
     * 
     * @type {string}
     * @memberof PvBatteryDeviceGatewayModel
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof PvBatteryDeviceGatewayModel
     */
    identifier?: string;
}
/**
 * 
 * @export
 * @interface PvBatteryDonutHistoryV2ResponseModel
 */
export interface PvBatteryDonutHistoryV2ResponseModel {
    /**
     * Value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    generation?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    toHome?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    toBattery?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    toGrid?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    consumption?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    fromPv?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    fromBattery?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    fromGrid?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    toInverter?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    fromInverter?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    toGcp?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    selfSufficiency?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    carCharging?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    electricCarCharging?: number;
    /**
     * Timeline is used to indicate the the usage from today until x days
     * @type {string}
     * @memberof PvBatteryDonutHistoryV2ResponseModel
     */
    timeline?: PvBatteryDonutHistoryV2ResponseModelTimelineEnum;
}

/**
* @export
* @enum {string}
*/
export enum PvBatteryDonutHistoryV2ResponseModelTimelineEnum {
    Currently = 'currently',
    _7days = '7days',
    _30days = '30days',
    _365days = '365days'
}

/**
 * 
 * @export
 * @interface PvBatteryForecastV2ResponseModel
 */
export interface PvBatteryForecastV2ResponseModel {
    /**
     * 
     * @type {string}
     * @memberof PvBatteryForecastV2ResponseModel
     */
    timestamp?: string;
    /**
     * 
     * @type {ForecastResponseValuesModel}
     * @memberof PvBatteryForecastV2ResponseModel
     */
    values?: ForecastResponseValuesModel;
}
/**
 * 
 * @export
 * @interface PvBatteryHistoryAggregationResponseModel
 */
export interface PvBatteryHistoryAggregationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof PvBatteryHistoryAggregationResponseModel
     */
    timestamp?: string;
    /**
     * 
     * @type {PvBatteryHistoryAggregationResponseValuesModel}
     * @memberof PvBatteryHistoryAggregationResponseModel
     */
    values?: PvBatteryHistoryAggregationResponseValuesModel;
}
/**
 * 
 * @export
 * @interface PvBatteryHistoryAggregationResponseValuesModel
 */
export interface PvBatteryHistoryAggregationResponseValuesModel {
    /**
     * Power value in W (avg)
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    pVpower?: number;
    /**
     * All generated energy from PV cells. Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    pV2homeTotal?: number;
    /**
     * Generated energy, direct consumption from PV cells. Cumulative value in Wh (first or last).
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    pV2homeToHome?: number;
    /**
     * Generated energy, direct consumption from PV cells. Cumulative value in Wh (first or last).
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    pV2home?: number;
    /**
     * Generated energy, exported to grid. Cumulative value in Wh (first or last).
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    pV2homeToGrid?: number;
    /**
     * Generated energy, for charging battery. Cumulative value in Wh (first or last).
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    pV2homeToBattery?: number;
    /**
     * Battery charge and discharge power
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    batteryPower?: number;
    /**
     * All energy from battery discharge. Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    batteryEnergyBattery2Home?: number;
    /**
     * All energy into battery charge (could also be from grid in some countries). Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    batteryEnergyHome2Battery?: number;
    /**
     * Value in % (avg)
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    batteryEnergyStateOfCharge?: number;
    /**
     * Battery current total
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    batteryCurrent?: number;
    /**
     * Inverter total AC power value in W (avg)
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    inverterPower?: number;
    /**
     * Inverter total AC energy intake. Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    inverterEnergyIn?: number;
    /**
     * Inverter total AC energy output. Cumulative value in Wh (first or last)
     * @type {number}
     * @memberof PvBatteryHistoryAggregationResponseValuesModel
     */
    inverterEnergyOut?: number;
}
/**
 * 
 * @export
 * @interface PvBatteryHistorySummaryResponseModel
 */
export interface PvBatteryHistorySummaryResponseModel {
    /**
     * 
     * @type {BenefitsResponseModel}
     * @memberof PvBatteryHistorySummaryResponseModel
     */
    benefits: BenefitsResponseModel;
    /**
     * 
     * @type {MeterDeltaResponseModel}
     * @memberof PvBatteryHistorySummaryResponseModel
     */
    meterDelta: MeterDeltaResponseModel;
}
/**
 * 
 * @export
 * @interface PvBatteryModifyModel
 */
export interface PvBatteryModifyModel {
    /**
     * Value in W
     * @type {number}
     * @memberof PvBatteryModifyModel
     */
    pvPeakPower?: number;
}
/**
 * 
 * @export
 * @interface RoomModel
 */
export interface RoomModel {
    /**
     * 
     * @type {string}
     * @memberof RoomModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RoomModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoomModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomModel
     */
    order: number;
    /**
     * 
     * @type {boolean}
     * @memberof RoomModel
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoomModel
     */
    kind: RoomModelKindEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RoomModel
     */
    complete: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoomModel
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomModel
     */
    type?: string;
}

/**
* @export
* @enum {string}
*/
export enum RoomModelKindEnum {
    Regular = 'regular',
    Virtual = 'virtual',
    Automatic = 'automatic'
}

/**
 * 
 * @export
 * @interface RoomModifyModel
 */
export interface RoomModifyModel {
    /**
     * 
     * @type {string}
     * @memberof RoomModifyModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoomModifyModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomModifyModel
     */
    order?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RoomModifyModel
     */
    status: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoomModifyModel
     */
    kind?: RoomModifyModelKindEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RoomModifyModel
     */
    complete: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoomModifyModel
     */
    icon: string;
    /**
     * 
     * @type {string}
     * @memberof RoomModifyModel
     */
    type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoomModifyModel
     */
    devices?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum RoomModifyModelKindEnum {
    Regular = 'regular',
    Virtual = 'virtual',
    Automatic = 'automatic'
}

/**
 * 
 * @export
 * @interface SafetyChargingModel
 */
export interface SafetyChargingModel {
    /**
     * If MinimumSocCharging mode is switched on or off
     * @type {boolean}
     * @memberof SafetyChargingModel
     */
    active: boolean;
    /**
     * The minimum SoC the EV shall have and keep to guarantee the range for the next usage.
     * @type {number}
     * @memberof SafetyChargingModel
     */
    minSoc?: number;
}
/**
 * 
 * @export
 * @interface SaleEditModel
 */
export interface SaleEditModel {
    /**
     * 
     * @type {Array<SaleTemplateStepEditModel>}
     * @memberof SaleEditModel
     */
    steps?: Array<SaleTemplateStepEditModel>;
}
/**
 * 
 * @export
 * @interface SaleModel
 */
export interface SaleModel {
    /**
     * 
     * @type {string}
     * @memberof SaleModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SaleModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SaleModel
     */
    saleTemplate: string;
    /**
     * 
     * @type {Array<SaleTemplateStepModel>}
     * @memberof SaleModel
     */
    steps: Array<SaleTemplateStepModel>;
}
/**
 * 
 * @export
 * @interface SaleTemplateStepEditModel
 */
export interface SaleTemplateStepEditModel {
    /**
     * 
     * @type {string}
     * @memberof SaleTemplateStepEditModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SaleTemplateStepEditModel
     */
    status?: SaleTemplateStepEditModelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SaleTemplateStepEditModel
     */
    installationId?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum SaleTemplateStepEditModelStatusEnum {
    Completed = 'completed',
    Skipped = 'skipped'
}

/**
 * 
 * @export
 * @interface SaleTemplateStepModel
 */
export interface SaleTemplateStepModel {
    /**
     * 
     * @type {string}
     * @memberof SaleTemplateStepModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SaleTemplateStepModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SaleTemplateStepModel
     */
    cmsNodeId: string;
    /**
     * 
     * @type {string}
     * @memberof SaleTemplateStepModel
     */
    plannedAt: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleTemplateStepModel
     */
    completedAt: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleTemplateStepModel
     */
    status: SaleTemplateStepModelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SaleTemplateStepModel
     */
    comment: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaleTemplateStepModel
     */
    isInstallation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaleTemplateStepModel
     */
    isUserCompleteable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaleTemplateStepModel
     */
    isVisible: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaleTemplateStepModel
     */
    installationId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaleTemplateStepModel
     */
    notify: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaleTemplateStepModel
     */
    isSkippable: boolean;
    /**
     * 
     * @type {number}
     * @memberof SaleTemplateStepModel
     */
    order: number;
}

/**
* @export
* @enum {string}
*/
export enum SaleTemplateStepModelStatusEnum {
    Completed = 'completed',
    Pending = 'pending',
    Skipped = 'skipped'
}

/**
 * 
 * @export
 * @interface SavingsResponseModel
 */
export interface SavingsResponseModel {
    /**
     * 
     * @type {ValueUnitResponseModel}
     * @memberof SavingsResponseModel
     */
    greenEnergy: ValueUnitResponseModel;
    /**
     * 
     * @type {ValueUnitResponseModel}
     * @memberof SavingsResponseModel
     */
    bestPrice: ValueUnitResponseModel;
    /**
     * 
     * @type {ValueUnitResponseModel}
     * @memberof SavingsResponseModel
     */
    savings: ValueUnitResponseModel;
}
/**
 * 
 * @export
 * @interface ScheduleChargingModel
 */
export interface ScheduleChargingModel {
    /**
     * If scheduling is switched on or off
     * @type {boolean}
     * @memberof ScheduleChargingModel
     */
    active: boolean;
    /**
     * The time to start charging in the format: HHMM
     * @type {string}
     * @memberof ScheduleChargingModel
     */
    start: string;
    /**
     * The time to end charging in the format: HHMM
     * @type {string}
     * @memberof ScheduleChargingModel
     */
    end: string;
    /**
     * The timezone which this site is scheduled in. Will be set by the backend to the sites timezone
     * @type {string}
     * @memberof ScheduleChargingModel
     */
    timezone?: string;
}
/**
 * 
 * @export
 * @interface SchedulingTimeModel
 */
export interface SchedulingTimeModel {
    /**
     * The start of the period within a single day
     * @type {string}
     * @memberof SchedulingTimeModel
     */
    start?: string;
    /**
     * The end of the period within a single day
     * @type {string}
     * @memberof SchedulingTimeModel
     */
    end?: string;
}
/**
 * 
 * @export
 * @interface ServiceUnvailable
 */
export interface ServiceUnvailable {
    /**
     * The error name
     * @type {string}
     * @memberof ServiceUnvailable
     */
    name: string;
    /**
     * An error message
     * @type {string}
     * @memberof ServiceUnvailable
     */
    message: string;
    /**
     * The status code of the exception
     * @type {number}
     * @memberof ServiceUnvailable
     */
    status: number;
    /**
     * A list of related errors
     * @type {Array<GenericError>}
     * @memberof ServiceUnvailable
     */
    errors?: Array<GenericError>;
    /**
     * The stack trace (only in development mode)
     * @type {string}
     * @memberof ServiceUnvailable
     */
    stack?: string;
}
/**
 * 
 * @export
 * @interface SignUpPreflightResponseModel
 */
export interface SignUpPreflightResponseModel {
    /**
     * Provides a url for the sign-up page also including the sign-up endpoint [Optional]
     * @type {string}
     * @memberof SignUpPreflightResponseModel
     */
    signUpPageUri: string;
    /**
     * Provides an endpoint to POST sign-up information [Optional]
     * @type {string}
     * @memberof SignUpPreflightResponseModel
     */
    signUpEndpoint: string;
    /**
     * CSRF token [Optional]
     * @type {string}
     * @memberof SignUpPreflightResponseModel
     */
    state: string;
}
/**
 * 
 * @export
 * @interface SignUpRequestModel
 */
export interface SignUpRequestModel {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequestModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequestModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequestModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequestModel
     */
    language: SignUpRequestModelLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequestModel
     */
    country: SignUpRequestModelCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequestModel
     */
    zipCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof SignUpRequestModel
     */
    consentTermsOfUse: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequestModel
     */
    consentTermsOfUseVersion: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequestModel
     */
    consentTermsOfUseLink?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SignUpRequestModel
     */
    consentSupport?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequestModel
     */
    consentSupportVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequestModel
     */
    consentSupportLink?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequestModel
     */
    campaignUUID?: string;
}

/**
* @export
* @enum {string}
*/
export enum SignUpRequestModelLanguageEnum {
    De = 'de',
    Sv = 'sv',
    It = 'it',
    En = 'en',
    Hu = 'hu'
}
/**
* @export
* @enum {string}
*/
export enum SignUpRequestModelCountryEnum {
    DeDe = 'de_DE',
    SvSe = 'sv_SE',
    ItIt = 'it_IT',
    EnGb = 'en_GB',
    HuHu = 'hu_HU',
    EnUk = 'en_UK'
}

/**
 * 
 * @export
 * @interface SignUpResponseModel
 */
export interface SignUpResponseModel {
    /**
     * 
     * @type {string}
     * @memberof SignUpResponseModel
     */
    status: string;
}
/**
 * 
 * @export
 * @interface SiteModel
 */
export interface SiteModel {
    /**
     * 
     * @type {string}
     * @memberof SiteModel
     */
    id: string;
    /**
     * Uses official TZ database name e.g. Europe/Berlin - https://data.iana.org/time-zones/releases/
     * @type {string}
     * @memberof SiteModel
     */
    timezone: string;
}
/**
 * 
 * @export
 * @interface SmartHomeDeviceCapabilities
 */
export interface SmartHomeDeviceCapabilities {
    /**
     * 
     * @type {Array<string>}
     * @memberof SmartHomeDeviceCapabilities
     */
    read?: Array<SmartHomeDeviceCapabilitiesReadEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SmartHomeDeviceCapabilities
     */
    write?: Array<SmartHomeDeviceCapabilitiesWriteEnum>;
}

/**
* @export
* @enum {string}
*/
export enum SmartHomeDeviceCapabilitiesReadEnum {
    Switch = 'switch',
    OutletInUse = 'outletInUse',
    Power = 'power',
    Energy = 'energy',
    DimLevel = 'dimLevel',
    Rgb = 'rgb'
}
/**
* @export
* @enum {string}
*/
export enum SmartHomeDeviceCapabilitiesWriteEnum {
    Switch = 'switch',
    DimLevel = 'dimLevel',
    Rgb = 'rgb'
}

/**
 * 
 * @export
 * @interface SmartHomeDeviceModel
 */
export interface SmartHomeDeviceModel {
    /**
     * 
     * @type {string}
     * @memberof SmartHomeDeviceModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartHomeDeviceModel
     */
    configId?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartHomeDeviceModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartHomeDeviceModel
     */
    type?: SmartHomeDeviceModelTypeEnum;
    /**
     * 
     * @type {SmartHomeDeviceCapabilities}
     * @memberof SmartHomeDeviceModel
     */
    capabilities?: SmartHomeDeviceCapabilities;
    /**
     * 
     * @type {string}
     * @memberof SmartHomeDeviceModel
     */
    vendor?: string;
}

/**
* @export
* @enum {string}
*/
export enum SmartHomeDeviceModelTypeEnum {
    Switch = 'switch',
    Socket = 'socket',
    Lb = 'lb',
    EnergyMonitor = 'energy_monitor'
}

/**
 * @type SmartHomeDeviceModifyModel
 * 
 * @export
 */
export type SmartHomeDeviceModifyModel = SmartHomeDeviceModifyModelOneOf | SmartHomeDeviceModifyModelOneOf1 | SmartHomeDeviceModifyModelOneOf2;
/**
 * 
 * @export
 * @interface SmartHomeDeviceModifyModelOneOf
 */
export interface SmartHomeDeviceModifyModelOneOf {
    /**
     * 
     * @type {string}
     * @memberof SmartHomeDeviceModifyModelOneOf
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof SmartHomeDeviceModifyModelOneOf
     */
    command: SmartHomeDeviceModifyModelOneOfCommandEnum;
    /**
     * Decimal color code
     * @type {number}
     * @memberof SmartHomeDeviceModifyModelOneOf
     */
    value: number;
}

/**
* @export
* @enum {string}
*/
export enum SmartHomeDeviceModifyModelOneOfCommandEnum {
    Rgb = 'rgb',
    DimLevel = 'dimLevel',
    Switch = 'switch'
}

/**
 * 
 * @export
 * @interface SmartHomeDeviceModifyModelOneOf1
 */
export interface SmartHomeDeviceModifyModelOneOf1 {
    /**
     * 
     * @type {string}
     * @memberof SmartHomeDeviceModifyModelOneOf1
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof SmartHomeDeviceModifyModelOneOf1
     */
    command: SmartHomeDeviceModifyModelOneOf1CommandEnum;
    /**
     * 
     * @type {number}
     * @memberof SmartHomeDeviceModifyModelOneOf1
     */
    value: number;
}

/**
* @export
* @enum {string}
*/
export enum SmartHomeDeviceModifyModelOneOf1CommandEnum {
    DimLevel = 'dimLevel'
}

/**
 * 
 * @export
 * @interface SmartHomeDeviceModifyModelOneOf2
 */
export interface SmartHomeDeviceModifyModelOneOf2 {
    /**
     * 
     * @type {string}
     * @memberof SmartHomeDeviceModifyModelOneOf2
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof SmartHomeDeviceModifyModelOneOf2
     */
    command: SmartHomeDeviceModifyModelOneOf2CommandEnum;
    /**
     * 
     * @type {number}
     * @memberof SmartHomeDeviceModifyModelOneOf2
     */
    value: number;
}

/**
* @export
* @enum {string}
*/
export enum SmartHomeDeviceModifyModelOneOf2CommandEnum {
    Switch = 'switch'
}

/**
 * 
 * @export
 * @interface SmartHomeHistoryAggregationResponseModel
 */
export interface SmartHomeHistoryAggregationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof SmartHomeHistoryAggregationResponseModel
     */
    timestamp: string;
    /**
     * 
     * @type {SmartHomeSectionResponseModel}
     * @memberof SmartHomeHistoryAggregationResponseModel
     */
    values: SmartHomeSectionResponseModel;
}
/**
 * 
 * @export
 * @interface SmartHomeSectionResponseModel
 */
export interface SmartHomeSectionResponseModel {
    /**
     * Value in W (avg)
     * @type {number}
     * @memberof SmartHomeSectionResponseModel
     */
    power?: number;
    /**
     * Value in Wh
     * @type {number}
     * @memberof SmartHomeSectionResponseModel
     */
    energy?: number;
}
/**
 * 
 * @export
 * @interface SolarAssistedChargingModel
 */
export interface SolarAssistedChargingModel {
    /**
     * If scheduling is switched on or off
     * @type {boolean}
     * @memberof SolarAssistedChargingModel
     */
    active: boolean;
    /**
     * Start charging at night, in case it is not possible to fully charge with solar power during the day
     * @type {boolean}
     * @memberof SolarAssistedChargingModel
     */
    nightChargingFallback?: boolean;
    /**
     * 
     * @type {SolarAssistedNightChargingModel}
     * @memberof SolarAssistedChargingModel
     */
    nightCharging?: SolarAssistedNightChargingModel;
    /**
     * Perceptual share of PV Peak power when charging should start. 0.75 means "start charging when PV Power is >= 75% of PV Peak Power
     * @type {number}
     * @memberof SolarAssistedChargingModel
     */
    pvPeakThreshold?: number;
}
/**
 * 
 * @export
 * @interface SolarAssistedNightChargingModel
 */
export interface SolarAssistedNightChargingModel {
    /**
     * The time to start night charging in the format: HHMM
     * @type {string}
     * @memberof SolarAssistedNightChargingModel
     */
    start: string;
    /**
     * The time to end night charging in the format: HHMM
     * @type {string}
     * @memberof SolarAssistedNightChargingModel
     */
    end: string;
}
/**
 * 
 * @export
 * @interface SolarCloudBalanceV2ResponseModel
 */
export interface SolarCloudBalanceV2ResponseModel {
    /**
     * 2018-05-11T00:00:00+01:00
     * @type {string}
     * @memberof SolarCloudBalanceV2ResponseModel
     */
    lastUpdate?: string;
    /**
     * value in Wh
     * @type {number}
     * @memberof SolarCloudBalanceV2ResponseModel
     */
    solarCloudBalanceTotal?: number;
}
/**
 * 
 * @export
 * @interface SolarCloudHistoryV2ResponseModel
 */
export interface SolarCloudHistoryV2ResponseModel {
    /**
     * 2018-05-11T00:00:00+01:00
     * @type {string}
     * @memberof SolarCloudHistoryV2ResponseModel
     */
    timestamp?: string;
    /**
     * value in Wh
     * @type {number}
     * @memberof SolarCloudHistoryV2ResponseModel
     */
    solarCloudBalanceDelta?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof SolarCloudHistoryV2ResponseModel
     */
    fromSolarCloud?: number;
    /**
     * value in Wh
     * @type {number}
     * @memberof SolarCloudHistoryV2ResponseModel
     */
    toSolarCloud?: number;
    /**
     * SolarCloud enabled for this period
     * @type {boolean}
     * @memberof SolarCloudHistoryV2ResponseModel
     */
    solarCloudEnabled?: boolean;
    /**
     * The timeline indicates the comparable data to the previous -x days. Depending on the selected resolution it will return different timelines e.g. `PT15M` = -1 days, `PT1H` = -1 days, `P1D` = -7 days, `P1M` = -30 days
     * @type {string}
     * @memberof SolarCloudHistoryV2ResponseModel
     */
    timeline?: SolarCloudHistoryV2ResponseModelTimelineEnum;
}

/**
* @export
* @enum {string}
*/
export enum SolarCloudHistoryV2ResponseModelTimelineEnum {
    Currently = 'currently',
    _1Days = '-1 days',
    _7Days = '-7 days',
    _30Days = '-30 days'
}

/**
 * 
 * @export
 * @interface SpotPriceModel
 */
export interface SpotPriceModel {
    /**
     * The timestamp in local time of the user
     * @type {string}
     * @memberof SpotPriceModel
     */
    timestamp: string;
    /**
     * The price amount as local value
     * @type {number}
     * @memberof SpotPriceModel
     */
    price: number;
}
/**
 * 
 * @export
 * @interface SpotPriceRegionModel
 */
export interface SpotPriceRegionModel {
    /**
     * The price region code
     * @type {string}
     * @memberof SpotPriceRegionModel
     */
    region: string;
    /**
     * The name to display for this region
     * @type {string}
     * @memberof SpotPriceRegionModel
     */
    displayName?: string;
}
/**
 * 
 * @export
 * @interface StepUpRequestModel
 */
export interface StepUpRequestModel {
    /**
     * 
     * @type {string}
     * @memberof StepUpRequestModel
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof StepUpRequestModel
     */
    country?: StepUpRequestModelCountryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof StepUpRequestModel
     */
    consentTermsOfUse: boolean;
    /**
     * 
     * @type {string}
     * @memberof StepUpRequestModel
     */
    consentTermsOfUseVersion: string;
    /**
     * 
     * @type {string}
     * @memberof StepUpRequestModel
     */
    consentTermsOfUseLink?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StepUpRequestModel
     */
    consentSupport?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StepUpRequestModel
     */
    consentSupportVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof StepUpRequestModel
     */
    consentSupportLink?: string;
}

/**
* @export
* @enum {string}
*/
export enum StepUpRequestModelCountryEnum {
    DeDe = 'de_DE',
    SvSe = 'sv_SE',
    ItIt = 'it_IT',
    EnGb = 'en_GB',
    HuHu = 'hu_HU',
    EnUk = 'en_UK'
}

/**
 * 
 * @export
 * @interface StepUpRequiredResponseModel
 */
export interface StepUpRequiredResponseModel {
    /**
     * In case an error occurs
     * @type {string}
     * @memberof StepUpRequiredResponseModel
     */
    error?: StepUpRequiredResponseModelErrorEnum;
    /**
     * If user is already IDP registered but not in app
     * @type {string}
     * @memberof StepUpRequiredResponseModel
     */
    stepUpRegistrationToken?: string;
    /**
     * ISO-3166 Country Code_ISO-639 Language Code
     * @type {string}
     * @memberof StepUpRequiredResponseModel
     */
    country: string;
    /**
     * Code_ISO-639 Language Code
     * @type {string}
     * @memberof StepUpRequiredResponseModel
     */
    language: string;
}

/**
* @export
* @enum {string}
*/
export enum StepUpRequiredResponseModelErrorEnum {
    CountryNotSupported = 'country_not_supported'
}

/**
 * 
 * @export
 * @interface SystemHealthModel
 */
export interface SystemHealthModel {
    /**
     * 
     * @type {string}
     * @memberof SystemHealthModel
     */
    status?: SystemHealthModelStatusEnum;
    /**
     * 
     * @type {Array<SystemHealthServicesModel>}
     * @memberof SystemHealthModel
     */
    services?: Array<SystemHealthServicesModel>;
}

/**
* @export
* @enum {string}
*/
export enum SystemHealthModelStatusEnum {
    Available = 'available',
    Unavailable = 'unavailable',
    Degraded = 'degraded'
}

/**
 * 
 * @export
 * @interface SystemHealthServicesModel
 */
export interface SystemHealthServicesModel {
    /**
     * 
     * @type {string}
     * @memberof SystemHealthServicesModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemHealthServicesModel
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface TariffDeleteModelRequest
 */
export interface TariffDeleteModelRequest {
    /**
     * The uuid of the given entry
     * @type {string}
     * @memberof TariffDeleteModelRequest
     */
    id: string;
}
/**
 * 
 * @export
 * @interface TariffModelRequest
 */
export interface TariffModelRequest {
    /**
     * The price amount as local value
     * @type {number}
     * @memberof TariffModelRequest
     */
    price: number;
    /**
     * The uuid of the given entry
     * @type {string}
     * @memberof TariffModelRequest
     */
    id?: string;
    /**
     * The start time for the current entry, in local time
     * @type {string}
     * @memberof TariffModelRequest
     */
    start: string;
    /**
     * The end time for the current entry, in local time
     * @type {string}
     * @memberof TariffModelRequest
     */
    end: string;
}
/**
 * 
 * @export
 * @interface TariffModelRequestItemV2
 */
export interface TariffModelRequestItemV2 {
    /**
     * The uuid of the given entry
     * @type {string}
     * @memberof TariffModelRequestItemV2
     */
    id?: string;
    /**
     * The customer id of the given entry
     * @type {string}
     * @memberof TariffModelRequestItemV2
     */
    customerId: string;
    /**
     * The tenant id of the given entry
     * @type {string}
     * @memberof TariffModelRequestItemV2
     */
    tenantId?: string;
    /**
     * The start date for the current tariff, in UTC time - ISO String
     * @type {string}
     * @memberof TariffModelRequestItemV2
     */
    startDate?: string;
    /**
     * Array of intervals of tariffs with corresponding prices in local value
     * @type {Array<PriceModelV2>}
     * @memberof TariffModelRequestItemV2
     */
    intervals: Array<PriceModelV2>;
}
/**
 * 
 * @export
 * @interface TariffModelResponse
 */
export interface TariffModelResponse {
    /**
     * 
     * @type {Array<PriceModel>}
     * @memberof TariffModelResponse
     */
    data?: Array<PriceModel>;
    /**
     * The type of the location type of this entry. Whether it is customer or tenant based
     * @type {string}
     * @memberof TariffModelResponse
     */
    type?: TariffModelResponseTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum TariffModelResponseTypeEnum {
    Customer = 'customer',
    Tenant = 'tenant'
}

/**
 * 
 * @export
 * @interface TariffModelResponseV2
 */
export interface TariffModelResponseV2 {
    /**
     * The uuid of the given entry
     * @type {string}
     * @memberof TariffModelResponseV2
     */
    id: string;
    /**
     * The customer id of the given entry
     * @type {string}
     * @memberof TariffModelResponseV2
     */
    customerId: string;
    /**
     * The tenant id of the given entry
     * @type {string}
     * @memberof TariffModelResponseV2
     */
    tenantId: string;
    /**
     * The start date for the current tariff, in UTC time - ISO String
     * @type {string}
     * @memberof TariffModelResponseV2
     */
    startDate?: string;
    /**
     * The end date for the current tariff, in UTC time - ISO String
     * @type {string}
     * @memberof TariffModelResponseV2
     */
    endDate?: string;
    /**
     * Array of intervals of tariffs with corresponding prices in local value
     * @type {Array<PriceModelV2>}
     * @memberof TariffModelResponseV2
     */
    intervals: Array<PriceModelV2>;
    /**
     * The type of the location type of this entry. Whether it is customer or tenant based
     * @type {string}
     * @memberof TariffModelResponseV2
     */
    type: TariffModelResponseV2TypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum TariffModelResponseV2TypeEnum {
    Customer = 'customer',
    Tenant = 'tenant'
}

/**
 * 
 * @export
 * @interface TariffUpdatesTariffRequest
 */
export interface TariffUpdatesTariffRequest {
    /**
     * 
     * @type {Array<TariffModelRequest>}
     * @memberof TariffUpdatesTariffRequest
     */
    prices?: Array<TariffModelRequest>;
}
/**
 * 
 * @export
 * @interface TenantAffiliateResponseModel
 */
export interface TenantAffiliateResponseModel {
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModel
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModel
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModel
     */
    tenantName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantAffiliateResponseModel
     */
    openRegistration?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModel
     */
    emailContact?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModel
     */
    emailActivation?: string;
    /**
     * 
     * @type {TenantAffiliateResponseModelSupportedLanguages}
     * @memberof TenantAffiliateResponseModel
     */
    supportedLanguages?: TenantAffiliateResponseModelSupportedLanguages;
    /**
     * 
     * @type {boolean}
     * @memberof TenantAffiliateResponseModel
     */
    pvSelfOnboarding?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModel
     */
    pvSelfOnboardingInstallEmail?: string;
    /**
     * 
     * @type {TenantAffiliateResponseModelPvContact}
     * @memberof TenantAffiliateResponseModel
     */
    pvSelfOnboardingContact?: TenantAffiliateResponseModelPvContact;
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModel
     */
    impressumUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModel
     */
    qualtricsUrlMobile?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModel
     */
    qualtricsUrlWeb?: string;
}
/**
 * 
 * @export
 * @interface TenantAffiliateResponseModelPvContact
 */
export interface TenantAffiliateResponseModelPvContact {
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModelPvContact
     */
    email?: string;
    /**
     * 
     * @type {TenantAffiliateResponseModelPvContactPhone}
     * @memberof TenantAffiliateResponseModelPvContact
     */
    phone?: TenantAffiliateResponseModelPvContactPhone;
}
/**
 * 
 * @export
 * @interface TenantAffiliateResponseModelPvContactPhone
 */
export interface TenantAffiliateResponseModelPvContactPhone {
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModelPvContactPhone
     */
    raw?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAffiliateResponseModelPvContactPhone
     */
    prettified?: string;
}
/**
 * 
 * @export
 * @interface TenantAffiliateResponseModelSupportedLanguages
 */
export interface TenantAffiliateResponseModelSupportedLanguages {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantAffiliateResponseModelSupportedLanguages
     */
    languages?: Array<string>;
}
/**
 * 
 * @export
 * @interface Unauthorized
 */
export interface Unauthorized {
    /**
     * The error name
     * @type {string}
     * @memberof Unauthorized
     */
    name: string;
    /**
     * An error message
     * @type {string}
     * @memberof Unauthorized
     */
    message: string;
    /**
     * The status code of the exception
     * @type {number}
     * @memberof Unauthorized
     */
    status: number;
    /**
     * A list of related errors
     * @type {Array<GenericError>}
     * @memberof Unauthorized
     */
    errors?: Array<GenericError>;
    /**
     * The stack trace (only in development mode)
     * @type {string}
     * @memberof Unauthorized
     */
    stack?: string;
}
/**
 * 
 * @export
 * @interface UnprocessableEntity
 */
export interface UnprocessableEntity {
    /**
     * The error name
     * @type {string}
     * @memberof UnprocessableEntity
     */
    name: string;
    /**
     * An error message
     * @type {string}
     * @memberof UnprocessableEntity
     */
    message: string;
    /**
     * The status code of the exception
     * @type {number}
     * @memberof UnprocessableEntity
     */
    status: number;
    /**
     * A list of related errors
     * @type {Array<GenericError>}
     * @memberof UnprocessableEntity
     */
    errors?: Array<GenericError>;
    /**
     * The stack trace (only in development mode)
     * @type {string}
     * @memberof UnprocessableEntity
     */
    stack?: string;
}
/**
 * 
 * @export
 * @interface UriResponseModel
 */
export interface UriResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UriResponseModel
     */
    uri: string;
    /**
     * 
     * @type {string}
     * @memberof UriResponseModel
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof UriResponseModel
     */
    idpId?: string;
    /**
     * 
     * @type {string}
     * @memberof UriResponseModel
     */
    clientId?: string;
}
/**
 * 
 * @export
 * @interface UserAccountChangeAddressModel
 */
export interface UserAccountChangeAddressModel {
    /**
     * Post code specific to the selected country
     * @type {string}
     * @memberof UserAccountChangeAddressModel
     */
    zipCode: string;
    /**
     * Country of user
     * @type {string}
     * @memberof UserAccountChangeAddressModel
     */
    country?: UserAccountChangeAddressModelCountryEnum;
}

/**
* @export
* @enum {string}
*/
export enum UserAccountChangeAddressModelCountryEnum {
    EnGb = 'en_GB',
    DeDe = 'de_DE',
    SvSe = 'sv_SE',
    ItIt = 'it_IT',
    PlPl = 'pl_PL',
    HuHu = 'hu_HU',
    CsCz = 'cs_CZ'
}

/**
 * 
 * @export
 * @interface UserDataConsentModel
 */
export interface UserDataConsentModel {
    /**
     * The type of consent. One unique value that matches in the UI.
     * @type {string}
     * @memberof UserDataConsentModel
     */
    name: UserDataConsentModelNameEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDataConsentModel
     */
    version: string;
    /**
     * Link to the agreed consent
     * @type {string}
     * @memberof UserDataConsentModel
     */
    link?: string;
    /**
     * User has signed/unsigned the consent already
     * @type {boolean}
     * @memberof UserDataConsentModel
     */
    opting?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UserDataConsentModelNameEnum {
    TermsOfUse = 'TermsOfUse',
    RemoteSupportAndManufacturer = 'RemoteSupportAndManufacturer',
    RemoteInstaller = 'RemoteInstaller'
}

/**
 * 
 * @export
 * @interface UserDataConsentsModel
 */
export interface UserDataConsentsModel {
    /**
     * All consents in one request
     * @type {Array<UserDataConsentModel>}
     * @memberof UserDataConsentsModel
     */
    consents: Array<UserDataConsentModel>;
}
/**
 * 
 * @export
 * @interface UserDataDownloadInfoModel
 */
export interface UserDataDownloadInfoModel {
    /**
     * The date when the user initiated the request
     * @type {string}
     * @memberof UserDataDownloadInfoModel
     */
    creationDate: string;
    /**
     * Date at which the download link will expire
     * @type {string}
     * @memberof UserDataDownloadInfoModel
     */
    expirationDate: string;
    /**
     * Flag to determine if the request was processed and the download url is available
     * @type {boolean}
     * @memberof UserDataDownloadInfoModel
     */
    isProcessed: boolean;
}
/**
 * 
 * @export
 * @interface UserDataDownloadUriModel
 */
export interface UserDataDownloadUriModel {
    /**
     * The download link for the data file. It is valid only for 10 seconds
     * @type {string}
     * @memberof UserDataDownloadUriModel
     */
    downloadLink: string;
}
/**
 * 
 * @export
 * @interface UserEncryptedReferenceModel
 */
export interface UserEncryptedReferenceModel {
    /**
     * Encrypted reference which can be used to pass to external entities
     * @type {string}
     * @memberof UserEncryptedReferenceModel
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    country: UserModelCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    language: UserModelLanguageEnum;
    /**
     * 
     * @type {NameModel}
     * @memberof UserModel
     */
    name?: NameModel;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    source?: UserModelSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    createdOn: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    hasSales?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    customerId?: string;
    /**
     * Get GVS user info is recommended to get actual GVS info
     * @type {boolean}
     * @memberof UserModel
     */
    shouldGetGvsInfo: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UserModelCountryEnum {
    Gb = 'GB',
    De = 'DE',
    It = 'IT',
    Hu = 'HU',
    Se = 'SE'
}
/**
* @export
* @enum {string}
*/
export enum UserModelLanguageEnum {
    En = 'en',
    De = 'de',
    It = 'it',
    Hu = 'hu',
    Sv = 'sv'
}
/**
* @export
* @enum {string}
*/
export enum UserModelSourceEnum {
    StepupRegistration = 'stepup-registration',
    OpenRegistration = 'open-registration',
    CsvUpload = 'csv-upload',
    PvbCrmGermany = 'pvb_crm_germany'
}

/**
 * 
 * @export
 * @interface UserPreferenceAddModel
 */
export interface UserPreferenceAddModel {
    /**
     * 
     * @type {Array<object>}
     * @memberof UserPreferenceAddModel
     */
    value: Array<object>;
}
/**
 * 
 * @export
 * @interface UserPreferenceModel
 */
export interface UserPreferenceModel {
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceModel
     */
    id: string;
    /**
     * 
     * @type {object}
     * @memberof UserPreferenceModel
     */
    value: object;
}
/**
 * 
 * @export
 * @interface UserPushNotificationAddTokenModel
 */
export interface UserPushNotificationAddTokenModel {
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationAddTokenModel
     */
    os: UserPushNotificationAddTokenModelOsEnum;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationAddTokenModel
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationAddTokenModel
     */
    token: string;
}

/**
* @export
* @enum {string}
*/
export enum UserPushNotificationAddTokenModelOsEnum {
    Ios = 'ios',
    Android = 'android',
    Web = 'web'
}

/**
 * 
 * @export
 * @interface UserPushNotificationRemoveTokenModel
 */
export interface UserPushNotificationRemoveTokenModel {
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationRemoveTokenModel
     */
    os: UserPushNotificationRemoveTokenModelOsEnum;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationRemoveTokenModel
     */
    uuid: string;
}

/**
* @export
* @enum {string}
*/
export enum UserPushNotificationRemoveTokenModelOsEnum {
    Ios = 'ios',
    Android = 'android',
    Web = 'web'
}

/**
 * 
 * @export
 * @interface ValueUnitResponseModel
 */
export interface ValueUnitResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ValueUnitResponseModel
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof ValueUnitResponseModel
     */
    unit: string;
}
/**
 * 
 * @export
 * @interface WallBoxAddDeviceRangeModel
 */
export interface WallBoxAddDeviceRangeModel {
    /**
     * 
     * @type {string}
     * @memberof WallBoxAddDeviceRangeModel
     */
    unit?: WallBoxAddDeviceRangeModelUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof WallBoxAddDeviceRangeModel
     */
    value?: number;
}

/**
* @export
* @enum {string}
*/
export enum WallBoxAddDeviceRangeModelUnitEnum {
    Km = 'km',
    Mile = 'mile'
}

/**
 * 
 * @export
 * @interface WallBoxAddElectricalVehicleDeviceRangeModel
 */
export interface WallBoxAddElectricalVehicleDeviceRangeModel {
    /**
     * Unit of measurement, either in kilometers (km) or miles
     * @type {string}
     * @memberof WallBoxAddElectricalVehicleDeviceRangeModel
     */
    unit: WallBoxAddElectricalVehicleDeviceRangeModelUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof WallBoxAddElectricalVehicleDeviceRangeModel
     */
    value: number;
}

/**
* @export
* @enum {string}
*/
export enum WallBoxAddElectricalVehicleDeviceRangeModelUnitEnum {
    Km = 'km',
    Mile = 'mile'
}

/**
 * 
 * @export
 * @interface WallBoxDeviceConfigurationCurrentModel
 */
export interface WallBoxDeviceConfigurationCurrentModel {
    /**
     * Value in A (Ampere)
     * @type {number}
     * @memberof WallBoxDeviceConfigurationCurrentModel
     */
    chargingLimitRecommendationStepSize?: number;
    /**
     * Value in A (Ampere)
     * @type {number}
     * @memberof WallBoxDeviceConfigurationCurrentModel
     */
    minimumOperationalThreshold?: number;
}
/**
 * 
 * @export
 * @interface WallBoxDeviceConfigurationModel
 */
export interface WallBoxDeviceConfigurationModel {
    /**
     * 
     * @type {WallBoxDeviceConfigurationCurrentModel}
     * @memberof WallBoxDeviceConfigurationModel
     */
    current?: WallBoxDeviceConfigurationCurrentModel;
    /**
     * Value in KW
     * @type {number}
     * @memberof WallBoxDeviceConfigurationModel
     */
    currentPowerLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof WallBoxDeviceConfigurationModel
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof WallBoxDeviceConfigurationModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WallBoxDeviceConfigurationModel
     */
    readingStartDate?: string;
    /**
     * 
     * @type {Array<WallBoxDeviceConfigurationVehicleModel>}
     * @memberof WallBoxDeviceConfigurationModel
     */
    vehicles?: Array<WallBoxDeviceConfigurationVehicleModel>;
}
/**
 * 
 * @export
 * @interface WallBoxDeviceConfigurationVehicleModel
 */
export interface WallBoxDeviceConfigurationVehicleModel {
    /**
     * 
     * @type {WallBoxAddDeviceRangeModel}
     * @memberof WallBoxDeviceConfigurationVehicleModel
     */
    range?: WallBoxAddDeviceRangeModel;
    /**
     * 
     * @type {WallBoxAddDeviceRangeModel}
     * @memberof WallBoxDeviceConfigurationVehicleModel
     */
    rangeCapacity?: WallBoxAddDeviceRangeModel;
    /**
     * In kWh
     * @type {number}
     * @memberof WallBoxDeviceConfigurationVehicleModel
     */
    batteryCapacity?: number;
    /**
     * 
     * @type {string}
     * @memberof WallBoxDeviceConfigurationVehicleModel
     */
    vehicleType?: string;
}
/**
 * 
 * @export
 * @interface WallBoxDeviceGatewayModel
 */
export interface WallBoxDeviceGatewayModel {
    /**
     * 
     * @type {string}
     * @memberof WallBoxDeviceGatewayModel
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof WallBoxDeviceGatewayModel
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof WallBoxDeviceGatewayModel
     */
    tokenExpiryDate?: string;
}
/**
 * 
 * @export
 * @interface WallBoxDeviceModifyModel
 */
export interface WallBoxDeviceModifyModel {
    /**
     * 
     * @type {string}
     * @memberof WallBoxDeviceModifyModel
     */
    chargingMode: WallBoxDeviceModifyModelChargingModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum WallBoxDeviceModifyModelChargingModeEnum {
    Start = 'start',
    Stop = 'stop'
}

/**
 * 
 * @export
 * @interface WallBoxDeviceResponseModel
 */
export interface WallBoxDeviceResponseModel {
    /**
     * 
     * @type {string}
     * @memberof WallBoxDeviceResponseModel
     */
    configId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallBoxDeviceResponseModel
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof WallBoxDeviceResponseModel
     */
    deviceType?: string;
    /**
     * 
     * @type {WallBoxDeviceConfigurationModel}
     * @memberof WallBoxDeviceResponseModel
     */
    deviceConfiguration?: WallBoxDeviceConfigurationModel;
    /**
     * 
     * @type {WallBoxDeviceGatewayModel}
     * @memberof WallBoxDeviceResponseModel
     */
    deviceGateway?: WallBoxDeviceGatewayModel;
}
/**
 * 
 * @export
 * @interface WallBoxSetChargingModeResponseModel
 */
export interface WallBoxSetChargingModeResponseModel {
    /**
     * Response Message whether setting charging mode was successful
     * @type {string}
     * @memberof WallBoxSetChargingModeResponseModel
     */
    message: string;
    /**
     * Response code whether setting charging mode was successful
     * @type {number}
     * @memberof WallBoxSetChargingModeResponseModel
     */
    code: number;
}
/**
 * 
 * @export
 * @interface WallBoxUpdateDeviceRequestModel
 */
export interface WallBoxUpdateDeviceRequestModel {
    /**
     * 
     * @type {WallBoxAddDeviceRangeModel}
     * @memberof WallBoxUpdateDeviceRequestModel
     */
    range: WallBoxAddDeviceRangeModel;
    /**
     * Electrical consumption per 100 range unit in kWh
     * @type {number}
     * @memberof WallBoxUpdateDeviceRequestModel
     */
    rangeCapacity?: number;
    /**
     * In kWh
     * @type {number}
     * @memberof WallBoxUpdateDeviceRequestModel
     */
    batteryCapacity: number;
    /**
     * 
     * @type {string}
     * @memberof WallBoxUpdateDeviceRequestModel
     */
    carType?: string;
}
/**
 * 
 * @export
 * @interface WallBoxUpdateElectricalVehicleRequestModel
 */
export interface WallBoxUpdateElectricalVehicleRequestModel {
    /**
     * 
     * @type {WallBoxAddElectricalVehicleDeviceRangeModel}
     * @memberof WallBoxUpdateElectricalVehicleRequestModel
     */
    range: WallBoxAddElectricalVehicleDeviceRangeModel;
    /**
     * Electrical consumption per 100 range unit in kWh
     * @type {number}
     * @memberof WallBoxUpdateElectricalVehicleRequestModel
     */
    rangeCapacity: number;
    /**
     * In kWh
     * @type {number}
     * @memberof WallBoxUpdateElectricalVehicleRequestModel
     */
    batteryCapacity: number;
    /**
     * 
     * @type {string}
     * @memberof WallBoxUpdateElectricalVehicleRequestModel
     */
    carType: string;
}
/**
 * 
 * @export
 * @interface WallboxFirmwareModel
 */
export interface WallboxFirmwareModel {
    /**
     * Configuration deviceGateway.identifier
     * @type {number}
     * @memberof WallboxFirmwareModel
     */
    deviceGatewayId?: number;
    /**
     * Name
     * @type {string}
     * @memberof WallboxFirmwareModel
     */
    name?: string;
    /**
     * Model identifier
     * @type {string}
     * @memberof WallboxFirmwareModel
     */
    model?: string;
    /**
     * Manifacturer
     * @type {string}
     * @memberof WallboxFirmwareModel
     */
    manufacturer?: string;
    /**
     * Current firmware version
     * @type {string}
     * @memberof WallboxFirmwareModel
     */
    currentVersion?: string;
    /**
     * Available next firmware version
     * @type {string}
     * @memberof WallboxFirmwareModel
     */
    availableVersion?: string;
    /**
     * Available next firmware version description
     * @type {string}
     * @memberof WallboxFirmwareModel
     */
    latestVersionDescription?: string;
    /**
     * Current firmware status. If UPTODATE, no availableVersion would be present.
     * @type {string}
     * @memberof WallboxFirmwareModel
     */
    status?: WallboxFirmwareModelStatusEnum;
    /**
     * Firmware status last update
     * @type {string}
     * @memberof WallboxFirmwareModel
     */
    updatedAt?: string;
}

/**
* @export
* @enum {string}
*/
export enum WallboxFirmwareModelStatusEnum {
    Uptodate = 'UPTODATE',
    Available = 'AVAILABLE',
    Requested = 'REQUESTED',
    Failed = 'FAILED',
    Alert = 'ALERT'
}

/**
 * 
 * @export
 * @interface WallboxFirmwareUpdateSettingsModel
 */
export interface WallboxFirmwareUpdateSettingsModel {
    /**
     * When true wallbox firmware updates happen automatically when available without the need for explicit user action
     * @type {boolean}
     * @memberof WallboxFirmwareUpdateSettingsModel
     */
    auto?: boolean;
}
/**
 * 
 * @export
 * @interface WallboxHistorySessionV2CalculatedResponseModel
 */
export interface WallboxHistorySessionV2CalculatedResponseModel {
    /**
     * Session Id
     * @type {string}
     * @memberof WallboxHistorySessionV2CalculatedResponseModel
     */
    session?: string;
    /**
     * 2018-05-11T00:00:00+01:00
     * @type {string}
     * @memberof WallboxHistorySessionV2CalculatedResponseModel
     */
    start?: string;
    /**
     * 2018-05-11T00:00:00+01:00
     * @type {string}
     * @memberof WallboxHistorySessionV2CalculatedResponseModel
     */
    finish?: string;
    /**
     * Value in Wh
     * @type {number}
     * @memberof WallboxHistorySessionV2CalculatedResponseModel
     */
    energyConsumption?: number;
    /**
     * Duration in seconds
     * @type {number}
     * @memberof WallboxHistorySessionV2CalculatedResponseModel
     */
    duration?: number;
    /**
     * Acutal cost in kw/h
     * @type {number}
     * @memberof WallboxHistorySessionV2CalculatedResponseModel
     */
    actualCost?: number;
    /**
     * Max cost in kw/h
     * @type {number}
     * @memberof WallboxHistorySessionV2CalculatedResponseModel
     */
    maxCost?: number;
}
/**
 * 
 * @export
 * @interface WallboxHistoryV2ResponseModel
 */
export interface WallboxHistoryV2ResponseModel {
    /**
     * 
     * @type {string}
     * @memberof WallboxHistoryV2ResponseModel
     */
    timestamp?: string;
    /**
     * Value in Wh
     * @type {number}
     * @memberof WallboxHistoryV2ResponseModel
     */
    energyConsumption?: number;
    /**
     * Value in W
     * @type {number}
     * @memberof WallboxHistoryV2ResponseModel
     */
    powerConsumption?: number;
    /**
     * The timeline indicates the comparable data to the previous -x days. Depending on the selected resolution it will return different timelines e.g. `PT15M` = -1 days, `PT1H` = -1 days, `P1D` = -7 days, `P1M` = -30 days
     * @type {string}
     * @memberof WallboxHistoryV2ResponseModel
     */
    timeline?: WallboxHistoryV2ResponseModelTimelineEnum;
}

/**
* @export
* @enum {string}
*/
export enum WallboxHistoryV2ResponseModelTimelineEnum {
    Currently = 'currently',
    _1Days = '-1 days',
    _7Days = '-7 days',
    _30Days = '-30 days'
}

/**
 * 
 * @export
 * @interface WallboxTamperingNotificationModel
 */
export interface WallboxTamperingNotificationModel {
    /**
     * Notification id
     * @type {string}
     * @memberof WallboxTamperingNotificationModel
     */
    id?: string;
    /**
     * Tamperiung notification type
     * @type {string}
     * @memberof WallboxTamperingNotificationModel
     */
    type?: WallboxTamperingNotificationModelTypeEnum;
    /**
     * Notification created at in ISO format
     * @type {string}
     * @memberof WallboxTamperingNotificationModel
     */
    timestamp?: string;
    /**
     * Configuration deviceGateway.identifier (evseId)
     * @type {number}
     * @memberof WallboxTamperingNotificationModel
     */
    deviceGatewayId?: number;
}

/**
* @export
* @enum {string}
*/
export enum WallboxTamperingNotificationModelTypeEnum {
    TamperDetectionActivated = 'tamper_detection_activated',
    InvalidFirmwareSignature = 'invalid_firmware_signature'
}

/**
 * 
 * @export
 * @interface WeatherForecastDailyDataResponseModel
 */
export interface WeatherForecastDailyDataResponseModel {
    /**
     * time
     * @type {number}
     * @memberof WeatherForecastDailyDataResponseModel
     */
    time: number;
    /**
     * Icon name
     * @type {string}
     * @memberof WeatherForecastDailyDataResponseModel
     */
    icon: WeatherForecastDailyDataResponseModelIconEnum;
    /**
     * sunriseTime
     * @type {number}
     * @memberof WeatherForecastDailyDataResponseModel
     */
    sunriseTime: number;
    /**
     * sunsetTime
     * @type {number}
     * @memberof WeatherForecastDailyDataResponseModel
     */
    sunsetTime: number;
    /**
     * dateTime
     * @type {number}
     * @memberof WeatherForecastDailyDataResponseModel
     */
    dateTime: number;
    /**
     * sunriseDateTime
     * @type {number}
     * @memberof WeatherForecastDailyDataResponseModel
     */
    sunriseDateTime: number;
    /**
     * sunsetDateTime
     * @type {number}
     * @memberof WeatherForecastDailyDataResponseModel
     */
    sunsetDateTime: number;
    /**
     * Temperature
     * @type {number}
     * @memberof WeatherForecastDailyDataResponseModel
     */
    temperatureMin: number;
    /**
     * Temperature
     * @type {number}
     * @memberof WeatherForecastDailyDataResponseModel
     */
    temperatureMax: number;
    /**
     * WindSpeed
     * @type {number}
     * @memberof WeatherForecastDailyDataResponseModel
     */
    windSpeed: number;
    /**
     * Cloud Cover in percentage
     * @type {number}
     * @memberof WeatherForecastDailyDataResponseModel
     */
    cloudCover: number;
}

/**
* @export
* @enum {string}
*/
export enum WeatherForecastDailyDataResponseModelIconEnum {
    ClearDay = 'clear-day',
    ClearNight = 'clear-night',
    Rain = 'rain',
    Snow = 'snow',
    Sleet = 'sleet',
    Wind = 'wind',
    Fog = 'fog',
    Cloudy = 'cloudy',
    PartlyCloudyDay = 'partly-cloudy-day',
    PartlyCloudyNight = 'partly-cloudy-night'
}

/**
 * 
 * @export
 * @interface WeatherForecastDailyResponseModel
 */
export interface WeatherForecastDailyResponseModel {
    /**
     * 
     * @type {Array<WeatherForecastDailyDataResponseModel>}
     * @memberof WeatherForecastDailyResponseModel
     */
    data: Array<WeatherForecastDailyDataResponseModel>;
}
/**
 * 
 * @export
 * @interface WeatherForecastResponseModel
 */
export interface WeatherForecastResponseModel {
    /**
     * latitude
     * @type {number}
     * @memberof WeatherForecastResponseModel
     */
    latitude: number;
    /**
     * longitude
     * @type {number}
     * @memberof WeatherForecastResponseModel
     */
    longitude: number;
    /**
     * timezone
     * @type {string}
     * @memberof WeatherForecastResponseModel
     */
    timezone: string;
    /**
     * 
     * @type {WeatherForecastDailyResponseModel}
     * @memberof WeatherForecastResponseModel
     */
    daily: WeatherForecastDailyResponseModel;
    /**
     * city
     * @type {string}
     * @memberof WeatherForecastResponseModel
     */
    city?: string;
}
/**
 * 
 * @export
 * @interface WeatherResponseModel
 */
export interface WeatherResponseModel {
    /**
     * Timestamp
     * @type {number}
     * @memberof WeatherResponseModel
     */
    time?: number;
    /**
     * Icon name
     * @type {string}
     * @memberof WeatherResponseModel
     */
    icon?: WeatherResponseModelIconEnum;
    /**
     * 
     * @type {number}
     * @memberof WeatherResponseModel
     */
    temperature?: number;
    /**
     * 
     * @type {number}
     * @memberof WeatherResponseModel
     */
    windSpeed?: number;
    /**
     * Cloud Cover in percentage
     * @type {number}
     * @memberof WeatherResponseModel
     */
    cloudCover?: number;
    /**
     * 
     * @type {string}
     * @memberof WeatherResponseModel
     */
    city?: string;
}

/**
* @export
* @enum {string}
*/
export enum WeatherResponseModelIconEnum {
    ClearDay = 'clear-day',
    ClearNight = 'clear-night',
    Rain = 'rain',
    Snow = 'snow',
    Sleet = 'sleet',
    Wind = 'wind',
    Fog = 'fog',
    Cloudy = 'cloudy',
    PartlyCloudyDay = 'partly-cloudy-day',
    PartlyCloudyNight = 'partly-cloudy-night'
}

