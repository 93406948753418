import { combineReducers } from 'redux';

import { EmobilityActionTypes } from '@store/enums';
import { initialState as wallboxInitialState } from './wallbox/reducer';
import { initialState as electricCarInitialState } from './electric-car/reducer';
import {
    LiveDataAction,
    EmobilityDeviceState,
    EmobilityAction,
} from '@store/types';

export const initialState = {
    electricCarData: electricCarInitialState,
    wallboxData: wallboxInitialState,
};

export function emobilityReducer(
    state: EmobilityDeviceState,
    action: EmobilityAction | LiveDataAction,
    initialState: EmobilityDeviceState,
): EmobilityDeviceState {
    switch (action.type) {
        case EmobilityActionTypes.WALLBOX_SET_ERROR:
        case EmobilityActionTypes.WALLBOX_SET_LOADING:
        case EmobilityActionTypes.WALLBOX_SET_DEVICE_DATA:
        case EmobilityActionTypes.ELECTRIC_CAR_SET_ERROR:
        case EmobilityActionTypes.ELECTRIC_CAR_SET_LOADING:
        case EmobilityActionTypes.ELECTRIC_CAR_SET_DEVICE_DATA:
            return {
                ...state,
                ...action.payload,
            };

        case EmobilityActionTypes.WALLBOX_SET_CHARGING_MODES_DATA:
        case EmobilityActionTypes.WALLBOX_SET_CHARGING_MODE_ERROR:
        case EmobilityActionTypes.WALLBOX_SET_CHARGING_MODES_ERROR:
        case EmobilityActionTypes.WALLBOX_SET_CHARGING_MODES_LOADING:
        case EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODES_DATA:
        case EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODE_ERROR:
        case EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODES_ERROR:
        case EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODES_LOADING:
            return {
                ...state,
                chargingModes: {
                    ...state.chargingModes,
                    ...action.payload,
                },
            };

        case EmobilityActionTypes.WALLBOX_RESET_STATE:
        case EmobilityActionTypes.ELECTRIC_CAR_RESET_STATE:
            return initialState;

        case EmobilityActionTypes.ELECTRIC_CAR_GET_CHARGING_HISTORY:
        case EmobilityActionTypes.ELECTRIC_CAR_GET_CHARGING_HISTORY_ERROR:
        case EmobilityActionTypes.ELECTRIC_CAR_GET_CHARGING_HISTORY_LOADING:
        case EmobilityActionTypes.WALLBOX_GET_CHARGING_HISTORY:
        case EmobilityActionTypes.WALLBOX_GET_CHARGING_HISTORY_ERROR:
        case EmobilityActionTypes.WALLBOX_GET_CHARGING_HISTORY_LOADING:
            return {
                ...state,
                chargingHistory: {
                    ...state.chargingHistory,
                    ...action.payload,
                },
            };

        case EmobilityActionTypes.WALLBOX_SET_FIRMWARE_UPDATE_SETTINGS:
        case EmobilityActionTypes.WALLBOX_SET_FIRMWARE_UPDATE_SETTINGS_ERROR:
            return {
                ...state,
                firmware: {
                    ...state.firmware,
                    updateSettings: {
                        ...state.firmware.updateSettings,
                        ...action.payload,
                    },
                },
            };

        case EmobilityActionTypes.ELECTRIC_CAR_SET_ADDRESS:
            return {
                ...state,
                electricCar: {
                    ...state.electricCar,
                    deviceConfiguration: {
                        ...state.electricCar!.deviceConfiguration!,
                        isAddressSet: true,
                    },
                },
            };

        default:
            return state;
    }
}

function createEmobilityReducer(initialState: EmobilityDeviceState) {
    return (
        state: EmobilityDeviceState,
        action: EmobilityAction | LiveDataAction,
    ) => {
        const isInitializationCall = state === undefined;
        if (!isInitializationCall)
            return emobilityReducer(state, action, initialState);
        return emobilityReducer(initialState, action, initialState);
    };
}

const reducer = combineReducers({
    electricCarData: createEmobilityReducer(electricCarInitialState),
    wallboxData: createEmobilityReducer(wallboxInitialState),
});

export default reducer;
